import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import useMedia from 'use-media'
import * as EmailValidator from 'email-validator'
import { useSpring, useTransition } from 'react-spring'
import { Progress, Container, CloseButton, StepButton } from '../FullScreenForm'
import Portal from '../Portal'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import FinalStep from './FinalStep'

const AskTheDoctor = ({ onClose }) => {
  const [toggle, setToggle] = useState(false)

  const [isError, setIsError] = useState(false)
  const [errorAnimation, toggleErrorAnimation] = useState(false)

  const [currentStep, setCurrentStep] = useState(0)
  const totalSteps = 4
  const [content, setContent] = useState(null)

  const [pitanje, setPitanje] = useState('')
  const [ime, setIme] = useState('')
  const [adresa, setAdresa] = useState('')
  const [acceptsPolicy, setAcceptsPolicy] = useState()

  const isMedium = useMedia({ maxWidth: 1100 })

  const handleClose = () => {
    setToggle(prev => !prev)
    setTimeout(onClose, 600)
  }

  const { wiggle } = useSpring({
    from: { x: 0 },
    wiggle: errorAnimation ? 1 : 0,
    config: { duration: 350 },
    // config: { mass: 1, friction: 30, tension: 150 }
  })

  useEffect(() => setToggle(true), [])

  const background = useTransition(toggle, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const handleStepBack = useCallback(() => {
    setCurrentStep(prev => prev - 1)
  }, [])
  const handleStepForward = useCallback(() => {
    if (
      (currentStep === 0 && pitanje.length > 0) ||
      (currentStep === 1 && ime.length > 0) ||
      (currentStep === 2 && EmailValidator.validate(adresa))
    ) {
      setCurrentStep(prev => prev + 1)
      setIsError(false)
    } else {
      toggleErrorAnimation(prev => !prev)
      setIsError(true)
    }
  }, [adresa, currentStep, ime.length, pitanje.length])

  useEffect(() => {
    const enterPress = e => {
      if (e.keyCode === 13) handleStepForward()
    }
    window.addEventListener('keydown', enterPress)
    return () => window.removeEventListener('keydown', enterPress)
  }, [handleStepForward])

  useEffect(() => {
    const handleSubmit = () => {
      axios({
        method: 'post',
        url: '/.netlify/functions/askthedoctor',
        data: {
          name: ime,
          question: pitanje,
          email: adresa,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(res => setIsError(false))
        .catch(err => setIsError(true))
      setCurrentStep(4)
    }

    if (currentStep === 0)
      setContent(
        <Step1 value={pitanje} setValue={setPitanje} wiggle={wiggle} />
      )
    if (currentStep === 1)
      setContent(<Step2 value={ime} setValue={setIme} wiggle={wiggle} />)
    if (currentStep === 2)
      setContent(<Step3 value={adresa} setValue={setAdresa} wiggle={wiggle} />)
    if (currentStep === 3)
      setContent(
        <Step4
          value={acceptsPolicy}
          setValue={setAcceptsPolicy}
          onSubmit={handleSubmit}
        />
      )
  }, [
    acceptsPolicy,
    adresa,
    currentStep,
    handleStepBack,
    handleStepForward,
    ime,
    pitanje,
    wiggle,
  ])

  if (currentStep === 4)
    return (
      <Portal>
        {background.map(
          ({ item, key, props }) =>
            item && (
              <Container key={key} style={props}>
                <CloseButton
                  onClick={handleClose}
                  width={isMedium ? 20 : 40}
                  strokeWidth={isMedium ? 4 : 2}
                />
                <FinalStep onClose={handleClose} isError={isError} />
              </Container>
            )
        )}
      </Portal>
    )

  return (
    <Portal>
      {background.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              {content}
              <Progress currentStep={currentStep} totalSteps={4} />
              <div>
                <>
                  <StepButton
                    onClick={currentStep > 0 ? handleStepBack : handleClose}
                    background="#c6c4b2"
                    fill="#fff"
                  />
                  {currentStep !== totalSteps - 1 ? (
                    <StepButton
                      disabled
                      onClick={handleStepForward}
                      background="#fff"
                      fill="#9c997c"
                      forward
                    />
                  ) : (
                    ''
                  )}
                </>
              </div>
            </Container>
          )
      )}
      <CloseButton
        onClick={handleClose}
        width={isMedium ? 20 : 40}
        strokeWidth={isMedium ? 4 : 2}
      />
    </Portal>
  )
}
AskTheDoctor.propTypes = {
  onClose: PropTypes.func.isRequired,
}
export default AskTheDoctor
