import styled from 'styled-components'
import { lighten } from 'polished'
import { colors } from '../theme'

export const FaqContainer = styled.section`
  min-height: 100vh;
  position: relative;
  padding: 180px 60px 60px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 850px) {
    display: block;
    padding: 110px 0 60px;
    margin: 0;
  }
`

// sticky menu for non-mobile version
export const StickyMenu = styled.nav`
  position: sticky;
  top: 100px;
  height: 333px;

  .searchbar {
    position: relative;
    input {
      width: 300px;
      height: 40px;
      background: ${colors.pinktouch};
      border-radius: 8px;
      border: none;
      padding: 1rem 1rem 1rem 3rem;
      font-weight: 300;
      font-size: 0.7rem;
      color: ${colors.greymid};
      :focus {
        outline: none;
      }
    }
    svg {
      position: absolute;
      left: 1rem;
      top: 12px;
    }
  }

  ul {
    margin-top: 63px;
    padding: 0;
    li {
      width: 221px;
      height: 46px;
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      button {
        font-family: 'Fira Sans';
        background: transparent;
        cursor: pointer;
        border: none;
        font-size: 1rem;
        color: rgba(18, 18, 20, 0.25);
        transition: color 230ms;
        height: 100%;
        width: 100%;
        text-align: left;
        :hover {
          color: ${colors.greymid};
        }
        :focus {
          outline: none;
        }
      }
      span {
        color: ${colors.greylight};
        font-size: 0.8rem;
      }
    }
    li#title--Trudnoća {
      border-left: 5px solid #f29eba;
    }
    li#title--Rađaonica {
      border-left: 5px solid #f8cad5;
    }
    li#title--Porod {
      border-left: 5px solid #e8397a;
    }
    li#title--Novorođenče {
      border-left: 5px solid #c6c4b3;
    }
    li#title--Majčinstvo {
      border-left: 5px solid #9c997c;
    }
  }

  ${props =>
    props.currentTitle
      ? `li#title--${props.currentTitle} button {
    color: ${colors.greymid};
  }`
      : ''}
`

export const ParagraphContainer = styled.section`
  min-height: 100vh;
  margin-top: -104px;
  margin-left: 80px;

  section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 87px;
    width: 780px;
    margin: 0;
  }

  article {
    width: 360px !important;
    margin-bottom: 30px;
  }

  @media (max-width: 1290px) {
    section {
      flex-direction: column;
      width: auto;
    }
    article {
      width: auto !important;
    }
  }

  @media (max-width: 1000px) {
    margin-left: 60px;
  }

  h2 {
    font-weight: 500;
    // todo: this is a non-responsive font size
    font-size: 20px;
    line-height: 1.2rem;
    margin: 10px 0;
  }
  #trudnoca h2 {
    color: #f29eba;
  }
  #trudnoca .search-highlight {
    background: ${lighten(0.17, '#f29eba')};
  }
  #radaonica h2 {
    color: #f8cad5;
  }
  #radaonica .search-highlight {
    background: ${lighten(0.1, '#f8cad5')};
  }
  #porod h2 {
    color: #e8397a;
  }
  #porod .search-highlight {
    background: ${lighten(0.33, '#e8397a')};
  }
  #novorodence h2 {
    color: #c6c4b3;
  }
  #novorodence .search-highlight {
    background: ${lighten(0.2, '#c6c4b3')};
  }
  #majcinstvo h2 {
    color: #9c997c;
  }
  #majcinstvo .search-highlight {
    background: ${lighten(0.35, '#9c997c')};
  }

  p {
    margin: 0 0 30px;
    font-weight: 200;
    // todo: this is a non-responsive font size
    font-size: 14px;
    line-height: 1.2rem;
    color: rgba(38, 37, 43, 0.75);
  }

  @media (max-width: 850px) {
    margin: 0;
    padding: 0 40px;
    section {
      padding: 40px 0 0;
      margin: 0;
    }
    section#trudnoca {
      padding: 20px 0 0;
    }
    article {
      margin-bottom: 0;
    }
  }
  @media (max-width: 550px) {
    padding: 0 25px;
  }
`

// sticky titles for mobile version
export const StickyTitle = styled.header`
  /* possibility for when header scroll mode is true */
  /* transform: translate(0, 70px); */
  z-index: 50;
  margin: 0;
  position: sticky;
  top: 0px;

  h3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 46px;
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    &.Trudnoća {
      background: #f49dba;
    }
    &.Rađaonica {
      background: #f9cad5;
    }
    &.Porod {
      background: #ea3579;
    }
    &.Novorođenče {
      background: #c6c4b2;
    }
    &.Majčinstvo {
      background: #9c997b;
    }
    transition: 300ms;
  }

  .mobile-searchbar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    background: #fff;
    /* input wrapper to hold the position absolute icon */
    div {
      position: relative;
      width: 80%;
      margin: 0 auto;
    }
    input {
      width: 100%;
      display: block;
      margin: 0 auto;
      min-width: 300px;
      height: 40px;
      background: ${colors.pinktouch};
      border-radius: 8px;
      border: none;
      padding: 1rem 1rem 1rem 3rem;
      font-weight: 300;
      font-size: 0.7rem;
      color: ${colors.greymid};
      :focus {
        outline: none;
      }
    }
    svg {
      position: absolute;
      left: 1rem;
      top: 12px;
    }
  }

  /* view width plus padding */
  /* width: calc(100% + 80px); */
  /* margin-left: -40px; */
  @media (max-width: 550px) {
    /* width: calc(100%); */
    /* margin-left: -0px; */
  }
`

export const ExpanderButton = styled.div`
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translate(0, -50%);
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:before {
    content: '▼';
    font-size: 15px;
    color: #fff;
  }
`
