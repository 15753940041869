import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import Tick from '../../icons/Tick'
import Cross from '../../icons/Cross'
import Button from '../Button'
import {
  Container,
  NotifyHeader,
  NotificationSubtitle,
} from '../FullScreenForm'

const FinalStep = ({ onClose, isError }) => {
  const isMedium = useMedia({ maxWidth: 1100 })
  const isMobile = useMedia({ maxWidth: 670 })
  const content = isError ? (
    <>
      <Cross width={isMobile ? 60 : 80} />
      <NotifyHeader>došlo je do greške na serveru</NotifyHeader>
      <NotificationSubtitle>
        Dr. Prku možeš kontaktirati izravno putem mail adrese:{' '}
        <a href="mailto:matija.prka@gmail.com">matija.prka@gmail.com</a>
      </NotificationSubtitle>
      <Button onClick={onClose}>česta pitanja</Button>
    </>
  ) : (
    <>
      <Tick width={isMobile ? 60 : 80} />
      <NotifyHeader>tvoje pitanje je uspješno proslijeđeno!</NotifyHeader>
      <NotificationSubtitle>
        Dr. Prka će ti odgovoriti prvom prilikom
      </NotificationSubtitle>
      <Button onClick={onClose}>česta pitanja</Button>
    </>
  )
  return <Container>{content}</Container>
}
FinalStep.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default FinalStep
