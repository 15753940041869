import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'
import { useTransition, animated } from 'react-spring'
import Portal from './Portal'
import CloseWindow from '../icons/CloseWindow'
import Button from './Button'

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: #fff;
`

const StyledClose = styled(CloseWindow)`
  position: fixed;
  top: 60px;
  right: 60px;
  cursor: pointer;
  z-index: 999999;

  @media (max-width: 650px) {
    top: 30px;
    right: 30px;
  }
`

const Content = styled.div`
  margin: 60px 90px;
  color: #959596;

  header {
    position: relative;
    width: 800px;
    margin-bottom: 70px;
    h2 {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.1rem;
      margin-bottom: 20px;
    }
    h3 {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    ${Button} {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  ul {
    margin: 0;
    padding-left: 40px;
    li {
      font-size: 1rem;
      line-height: 1.5rem;
      list-style: none;
      position: relative;
      margin-bottom: 10px;
      :before {
        content: '•';
        font-size: 3.5rem;
        position: absolute;
        left: -40px;
        color: #9c997b;
      }
    }
  }

  @media (max-width: 1100px) {
    header {
      width: auto;
      ${Button} {
        position: relative;
      }
    }
  }
  @media (max-width: 850px) {
    margin: 60px 60px;
  }
  @media (max-width: 650px) {
    margin: 60px 20px;
    header {
      text-align: center;
      margin: 80px 0 40px;
      ${Button} {
        margin: 0 auto;
        display: block;
        font-size: 14px;
      }
    }
    ul li {
      font-size: 14px;
      &:before {
        left: -30px;
      }
    }
  }
`

const DrPrka = ({ onClose }) => {
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    setToggle(true)
  }, [])
  const isMedium = useMedia({ maxWidth: 1100 })

  const background = useTransition(toggle, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  const handleClose = () => {
    setToggle(prev => !prev)
    setTimeout(onClose, 600)
  }

  return (
    <Portal>
      {background.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              <StyledClose
                onClick={handleClose}
                stroke="#5D5C60"
                strokeWidth={isMedium ? 4 : 2}
                width={isMedium ? 20 : 40}
              />
              <Content>
                <header>
                  <h2>Prim. Matija Prka, dr. med.</h2>
                  <h3>
                    Specijalist ginekologije i opstetricije
                    <br />
                    Subspecijalist fetalne medicine i opstetricije
                  </h3>
                  <Button
                    onClick={() =>
                      window.open(
                        'http://www.poliklinika-veritas.hr/doktor/matija-prka/',
                        '_blank'
                      )
                    }
                  >
                    Poliklinika Veritas
                  </Button>
                </header>
                <ul>
                  <li>
                    Prim. Matija Prka, dr. med., rođen je 1. rujna 1974. u
                    Slavonskom Brodu.
                  </li>
                  <li>
                    Diplomirao je u svibnju 2000. na Medicinskom fakultetu u
                    Zagrebu.
                  </li>
                  <li>
                    U akademskoj godini 1999./2000. dobitnik je Rektorove
                    nagrade Sveučilišta u Zagrebu.
                  </li>
                  <li>
                    Specijalistički ispit iz ginekologije i opstetricije položio
                    je u rujnu 2008., a subspecijalistički ispit iz fetalne
                    medicine i opstetricije u veljači 2017. godine.
                  </li>
                  <li>U listopadu 2018. priznat mu je naziv primarijus.</li>
                  <li>
                    Zaposlen je u Klinici za ginekologiju i porodništvo KB
                    „Sveti Duh“ u Zagrebu.
                  </li>
                  <li>
                    U siječnju 2017. izabran je na Hrvatskom katoličkom
                    sveučilištu u Zagrebu u naslovno suradničko zvanje asistent
                    u znanstvenom području biomedicina i zdravstvo, polje
                    kliničke medicinske znanosti, grana ginekologija.
                  </li>
                  <li>
                    U prosincu 2017. i 2018. završio je u Londonu dvodnevni
                    napredni tečaj iz fetalne medicine u organizaciji „The Fetal
                    Medicine Foundation“.
                  </li>
                  <li>
                    Član je radne skupine pilot projekta „Rodilište - prijatelj
                    majki“ Ministarstva zdravstva Republike Hrvatske i UNICEF-a.
                  </li>
                  <li>
                    Od studenog 2012. član je uredničkog odbora „Liječničkih
                    novina“, glasila Hrvatske liječničke komore.
                  </li>
                </ul>
              </Content>
            </Container>
          )
      )}
    </Portal>
  )
}

export default DrPrka
