import React, { useState, useRef, useEffect, useCallback } from 'react'
import useMedia from 'use-media'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactBanner from '../components/cesta-pitanja/ContactBanner'
import Search from '../icons/Search'
import {
  Trudnoća,
  Rađaonica,
  Porod,
  Novorođenče,
  Majčinstvo,
} from '../data/cesta-pitanja'
import AskTheDoctor from '../components/AskTheDoctor'
import useOnScreen from '../hooks/useOnScreen'
import {
  FaqContainer,
  StickyMenu,
  ParagraphContainer,
  StickyTitle,
  ExpanderButton,
} from '../components/cesta-pitanja/cesta-pitanja.styled'

const CestaPitanjaPage = () => {
  const [search, setSearch] = useState('')
  const [subsection, setSubsection] = useState()
  const [isQuestionDialogOpen, setIsQuestionDialogOpen] = useState(false)

  const isMobile = useMedia({ maxWidth: 850 })
  const [isMobileCollapsed, setIsMobileCollapsed] = useState(false)

  const openQuestionDialog = useCallback(
    () => setIsQuestionDialogOpen(true),
    []
  )
  const closeQuestionDialog = useCallback(
    () => setIsQuestionDialogOpen(false),
    []
  )

  const trudnocaRef = useRef()
  const trudnocaIntersect = useOnScreen(trudnocaRef, '300px')
  const radaonicaRef = useRef()
  const radaonicaIntersect = useOnScreen(radaonicaRef, '300px')
  const porodRef = useRef()
  const porodIntersect = useOnScreen(porodRef, '300px')
  const novorodenceRef = useRef()
  const novorodenceIntersect = useOnScreen(novorodenceRef, '300px')
  const majcinstvoRef = useRef()
  const majcinstvoIntersect = useOnScreen(majcinstvoRef, '300px')

  useEffect(() => {
    const closeMobileCollapse = () => setIsMobileCollapsed(false)
    if (isMobileCollapsed) {
      window.addEventListener('click', closeMobileCollapse)
      window.addEventListener('scroll', closeMobileCollapse)
    } else {
      window.removeEventListener('click', closeMobileCollapse)
      window.addEventListener('scroll', closeMobileCollapse)
    }
    return () => window.removeEventListener('click', closeMobileCollapse)
  }, [isMobileCollapsed])

  useEffect(() => {
    if (trudnocaIntersect) setSubsection('Trudnoća')
    if (radaonicaIntersect) setSubsection('Rađaonica')
    if (porodIntersect) setSubsection('Porod')
    if (novorodenceIntersect) setSubsection('Novorođenče')
    if (majcinstvoIntersect) setSubsection('Majčinstvo')
  }, [
    majcinstvoIntersect,
    novorodenceIntersect,
    porodIntersect,
    radaonicaIntersect,
    trudnocaIntersect,
  ])

  const data = {
    trudnoca: [],
    radaonica: [],
    porod: [],
    novorodence: [],
    majcinstvo: [],
  }
  Trudnoća.forEach(para => {
    if (!search) return data.trudnoca.push(para)
    if (
      para.title.toLowerCase().includes(search.toLowerCase()) ||
      para.body.toLowerCase().includes(search.toLowerCase())
    ) {
      data.trudnoca.push({
        ...para,
        title: para.title.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
        body: para.body.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
      })
    }
  })
  Rađaonica.forEach(para => {
    if (
      para.title.toLowerCase().includes(search.toLowerCase()) ||
      para.body.toLowerCase().includes(search.toLowerCase())
    ) {
      data.radaonica.push({
        ...para,
        title: para.title.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
        body: para.body.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
      })
    }
  })
  Porod.forEach(para => {
    if (
      para.title.toLowerCase().includes(search.toLowerCase()) ||
      para.body.toLowerCase().includes(search.toLowerCase())
    ) {
      data.porod.push({
        ...para,
        title: para.title.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
        body: para.body.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
      })
    }
  })
  Novorođenče.forEach(para => {
    if (
      para.title.toLowerCase().includes(search.toLowerCase()) ||
      para.body.toLowerCase().includes(search.toLowerCase())
    ) {
      data.novorodence.push({
        ...para,
        title: para.title.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
        body: para.body.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
      })
    }
  })
  Majčinstvo.forEach(para => {
    if (
      para.title.toLowerCase().includes(search.toLowerCase()) ||
      para.body.toLowerCase().includes(search.toLowerCase())
    ) {
      data.majcinstvo.push({
        ...para,
        title: para.title.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
        body: para.body.replace(
          new RegExp(search, 'i'),
          `<span class="search-highlight">${search}</span>`
        ),
      })
    }
  })

  return (
    <Layout subsection={subsection}>
      <SEO title="Česta Pitanja" />
      <FaqContainer>
        {isMobile ? (
          <StickyTitle>
            {isMobileCollapsed ? (
              <>
                <h3
                  className="sticky-subsection Trudnoća"
                  onClick={() => {
                    //! be careful with this window object :S
                    window.scrollTo(0, trudnocaRef.current.offsetTop - 80)
                    setIsMobileCollapsed(prev => !prev)
                  }}
                >
                  Trudnoća {subsection === 'Trudnoća' ? <ExpanderButton /> : ''}
                </h3>
                <h3
                  className="sticky-subsection Rađaonica"
                  onClick={() => {
                    window.scrollTo(0, radaonicaRef.current.offsetTop - 80)
                    setIsMobileCollapsed(prev => !prev)
                  }}
                >
                  Rađaonica{' '}
                  {subsection === 'Rađaonica' ? <ExpanderButton /> : ''}
                </h3>
                <h3
                  className="sticky-subsection Porod"
                  onClick={() => {
                    window.scrollTo(0, porodRef.current.offsetTop - 80)
                    setIsMobileCollapsed(prev => !prev)
                  }}
                >
                  Porod {subsection === 'Porod' ? <ExpanderButton /> : ''}
                </h3>
                <h3
                  className="sticky-subsection Novorođenče"
                  onClick={() => {
                    window.scrollTo(0, novorodenceRef.current.offsetTop - 80)
                    setIsMobileCollapsed(prev => !prev)
                  }}
                >
                  Novorođenče{' '}
                  {subsection === 'Novorođenče' ? <ExpanderButton /> : ''}
                </h3>
                <h3
                  className="sticky-subsection Majčinstvo"
                  onClick={() => {
                    window.scrollTo(0, majcinstvoRef.current.offsetTop - 80)
                    setIsMobileCollapsed(prev => !prev)
                  }}
                >
                  Majčinstvo{' '}
                  {subsection === 'Majčinstvo' ? <ExpanderButton /> : ''}
                </h3>
              </>
            ) : (
              <>
                <h3 className={`sticky-subsection ${subsection}`}>
                  {subsection}{' '}
                  <ExpanderButton
                    onClick={() => setIsMobileCollapsed(prev => !prev)}
                  />
                </h3>
                <div className="mobile-searchbar">
                  <div>
                    <input
                      placeholder="pretraži prema ključnoj riječi"
                      onChange={({ target: { value } }) => setSearch(value)}
                      value={search}
                    />
                    <Search />
                  </div>
                </div>
              </>
            )}
          </StickyTitle>
        ) : (
          ''
        )}
        {!isMobile ? (
          <StickyMenu currentTitle={subsection}>
            <div className="searchbar">
              <input
                placeholder="pretraži prema ključnoj riječi"
                onChange={({ target: { value } }) => setSearch(value)}
                value={search}
              />
              <Search />
            </div>
            <ul>
              <li id="title--Trudnoća">
                <button
                  type="button"
                  onClick={() => {
                    if (!data.novorodence.length) return
                    trudnocaRef.current.scrollIntoView()
                  }}
                  disabled={!data.novorodence.length}
                >
                  Trudnoća
                </button>
              </li>
              <li id="title--Rađaonica">
                <button
                  type="button"
                  onClick={() => {
                    radaonicaRef.current.scrollIntoView()
                  }}
                  disabled={!data.radaonica.length}
                >
                  Rađaonica
                </button>
              </li>
              <li id="title--Porod">
                <button
                  type="button"
                  onClick={() => {
                    porodRef.current.scrollIntoView()
                  }}
                  disabled={!data.porod.length}
                >
                  Porod
                </button>
              </li>
              <li id="title--Novorođenče">
                <button
                  type="button"
                  onClick={() => {
                    novorodenceRef.current.scrollIntoView()
                  }}
                  disabled={!data.novorodence.length}
                >
                  Novorođenče
                </button>
              </li>
              <li id="title--Majčinstvo">
                <button
                  type="button"
                  onClick={() => {
                    majcinstvoRef.current.scrollIntoView()
                  }}
                  disabled={!data.majcinstvo.length}
                >
                  Majčinstvo
                </button>
              </li>
            </ul>
          </StickyMenu>
        ) : (
          ''
        )}
        <ParagraphContainer>
          {data.trudnoca.length ? (
            <section id="trudnoca" ref={trudnocaRef}>
              <>
                <div>
                  {data.trudnoca
                    .filter(question => question.column === 1)
                    .map(question => (
                      <article>
                        <h2
                          dangerouslySetInnerHTML={{ __html: question.title }}
                        />
                        <p
                          dangerouslySetInnerHTML={{ __html: question.body }}
                        />
                      </article>
                    ))}
                </div>
                <div>
                  {data.trudnoca
                    .filter(question => question.column === 2)
                    .map(question => (
                      <article>
                        <h2
                          dangerouslySetInnerHTML={{ __html: question.title }}
                        />
                        <p
                          dangerouslySetInnerHTML={{ __html: question.body }}
                        />
                      </article>
                    ))}
                </div>
              </>
            </section>
          ) : (
            ''
          )}

          {data.radaonica.length ? (
            <section id="radaonica" ref={radaonicaRef}>
              <div>
                {data.radaonica
                  .filter(question => question.column === 1)
                  .map(question => (
                    <article>
                      <h2
                        dangerouslySetInnerHTML={{ __html: question.title }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: question.body }} />
                    </article>
                  ))}
              </div>
              <div>
                {data.radaonica
                  .filter(question => question.column === 2)
                  .map(question => (
                    <article>
                      <h2
                        dangerouslySetInnerHTML={{ __html: question.title }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: question.body }} />
                    </article>
                  ))}
              </div>
            </section>
          ) : (
            ''
          )}

          {data.porod.length ? (
            <section id="porod" ref={porodRef}>
              <div>
                {data.porod
                  .filter(question => question.column === 1)
                  .map(question => (
                    <article>
                      <h2
                        dangerouslySetInnerHTML={{ __html: question.title }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: question.body }} />
                    </article>
                  ))}
              </div>
              <div>
                {data.porod
                  .filter(question => question.column === 2)
                  .map(question => (
                    <article>
                      <h2
                        dangerouslySetInnerHTML={{ __html: question.title }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: question.body }} />
                    </article>
                  ))}
              </div>
            </section>
          ) : (
            ''
          )}

          {data.novorodence.length ? (
            <section id="novorodence" ref={novorodenceRef}>
              <div>
                {data.novorodence
                  .filter(question => question.column === 1)
                  .map(question => (
                    <article>
                      <h2
                        dangerouslySetInnerHTML={{ __html: question.title }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: question.body }} />
                    </article>
                  ))}
              </div>
              <div>
                {data.novorodence
                  .filter(question => question.column === 2)
                  .map(question => (
                    <article>
                      <h2
                        dangerouslySetInnerHTML={{ __html: question.title }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: question.body }} />
                    </article>
                  ))}
              </div>
            </section>
          ) : (
            ''
          )}

          {data.majcinstvo.length ? (
            <section id="majcinstvo" ref={majcinstvoRef}>
              <div>
                {data.majcinstvo
                  .filter(question => question.column === 1)
                  .map(question => (
                    <article>
                      <h2
                        dangerouslySetInnerHTML={{ __html: question.title }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: question.body }} />
                    </article>
                  ))}
              </div>
              <div>
                {data.majcinstvo
                  .filter(question => question.column === 2)
                  .map(question => (
                    <article>
                      <h2
                        dangerouslySetInnerHTML={{ __html: question.title }}
                      />
                      <p dangerouslySetInnerHTML={{ __html: question.body }} />
                    </article>
                  ))}
              </div>
            </section>
          ) : (
            ''
          )}
        </ParagraphContainer>
      </FaqContainer>
      <ContactBanner
        handleOpen={openQuestionDialog}
        handleClose={closeQuestionDialog}
      />
      {isQuestionDialogOpen ? (
        <AskTheDoctor onClose={closeQuestionDialog} />
      ) : (
        ''
      )}
    </Layout>
  )
}

export default CestaPitanjaPage
