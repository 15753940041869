import React from 'react'

const SvgMailPath = ({ width = 36, ...props }) => (
  <svg width={width} height={27} fill="none" viewBox="0 0 36 26" {...props}>
    <path
      d="M31.727 0H4.273C1.917 0 0 1.818 0 4.052v18.896c0 2.234 1.917 4.051 4.273 4.051h27.454C34.083 27 36 25.182 36 22.948V4.052C36 1.818 34.083 0 31.727 0zm0 23.422H4.273c-.347 0-.65-.221-.65-.474V6.55L16.04 16.9c.254.213.578.33.911.33h2.097c.333 0 .658-.117.912-.33l12.418-10.35v16.397c0 .253-.304.474-.65.474zM18 13.68L5.923 3.577h24.154L18 13.68z"
      fill="#fff"
    />
  </svg>
)

export default SvgMailPath
