import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'
import { useTransition, animated } from 'react-spring'
import Throttle from 'lodash.throttle'
import Mail from '../../icons/MailPath'

const Wrapper = styled.div`
  width: ${props => (props.isExpanded ? '100vw' : '60px')};
  height: 70px;
  position: fixed;
  bottom: 20px;
  right: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 850px) {
    height: 60px;
  }
`

const IconContainer = styled.div`
  z-index: 10;
  height: 100%;
  width: 96px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 7px 0 0 7px;
  transition: 0.6s;
  background: #9c997b;

  .icon {
    z-index: 10;
    position: absolute;
    top: 22px;
    right: 30px;
  }

  @media (max-width: 850px) {
    height: 60px;
    width: 60px;
    justify-content: flex-start;
    padding-left: 15px;
    .icon {
      top: 16px;
      right: 16px;
    }
  }
`

const TextContainer = styled(animated.p)`
  margin: 0;
  position: absolute;
  left: 0;
  width: 100vw;
  z-index: 9;
  background: #9c997b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 0.9rem;
  line-height: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  @media (max-width: 850px) {
    line-height: 1rem;
    font-size: 12px;
    text-align: left;
    margin: 0;
    padding: 0 60px 0 15px;
  }

  @media (max-width: 350px) {
    font-size: 10px;
  }
`

const ContactBanner = ({ handleOpen }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isMobile = useMedia({ maxWidth: 850 })
  const [isVisible, setIsVisible] = useState(false)
  setTimeout(() => setIsVisible(true), 5000)

  const transitions = useTransition(isExpanded, item => item, {
    from: { opacity: 0, transform: 'translate3d(100px, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0px, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-50px, 0, 0)' },
  })

  const handleExpand = useCallback(
    Throttle(() => setIsExpanded(true), 300),
    []
  )
  const handleCollapse = useCallback(
    Throttle(() => setIsExpanded(false), 3000),
    []
  )

  return (
    <Wrapper
      onClick={isExpanded ? handleOpen : handleExpand}
      isExpanded={isExpanded}
      onMouseEnter={isExpanded ? null : handleExpand}
      onMouseLeave={isExpanded ? handleCollapse : null}
    >
      {transitions.map(({ item, key, props }) =>
        item ? (
          <TextContainer key={key} style={props}>
            Ne možeš pronaći odgovor na pitanje?
            <br />
            Pitaj našeg dr. Matiju sve što te zanima o komplikacijama u trudnoći
            i sve o porođaju!
          </TextContainer>
        ) : (
          ''
        )
      )}
      <IconContainer style={isVisible ? {} : { transform: 'translateX(100%)' }}>
        <Mail className="icon" width={isMobile ? 30 : 36} />
      </IconContainer>
    </Wrapper>
  )
}
export default ContactBanner
