import React, { useState } from 'react'
import styled from 'styled-components'
import { StyledButton, FormInput } from '../FullScreenForm'
import DrPrka from '../DrPrka'

const PrvkaDetails = styled.div`
  position: absolute;
  width: 437px;
  top: 80px;
  left: 60px;

  h4 {
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 28px;
    text-align: left;
    margin: 0 0 10px;
  }

  button {
    margin: 0;
  }

  @media (max-width: 650px) {
    top: 70px;
    left: 20px;
    width: 90vw;
    h4 {
      width: 265px;
      text-align: center;
      margin: 0 auto 10px;
      line-height: 18px;
      font-size: 12px;
    }
    button {
      display: block;
      margin: 0 auto;
    }
  }
`

const MobileTweak = styled.h4`
  margin-bottom: 40px;
  @media (max-width: 488px) {
    margin-bottom: 22px !important;
  }
`

const Step1 = ({ value, setValue, wiggle }) => {
  const [showDrPrka, setShowDrPrka] = useState(false)

  return (
    <>
      <h2>Tvoje pitanje *</h2>
      <MobileTweak>
        * dr. Prka će odgovarati na pitanja vezana za trudnoću i porod
      </MobileTweak>
      <FormInput
        autoFocus
        type="text"
        placeholder="Moje pitanje..."
        value={value}
        onChange={e => setValue(e.target.value)}
        style={{
          transform: wiggle
            .interpolate({
              range: [0.08, 0.25, 0.41, 0.58, 0.75, 0.92, 1],
              output: [0, -8, 8, -8, -4, 4, 0],
            })
            .interpolate(x => `translate3d(${x}px, 0, 0)`),
        }}
      />
      <PrvkaDetails>
        <h4>
          Na tvoja pitanja odgovara specijalist ginekologije i opstetricije,
          prim. Matija Prka, dr. med.
        </h4>
        <StyledButton onClick={() => setShowDrPrka(true)}>
          Više o dr. matiji
        </StyledButton>
      </PrvkaDetails>
      {showDrPrka ? <DrPrka onClose={() => setShowDrPrka(false)} /> : ''}
    </>
  )
}

export default Step1
