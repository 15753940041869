import React from 'react'
import { FormInput } from '../FullScreenForm'

const Step2 = ({ value, setValue, wiggle }) => (
  <>
    <h2>Tvoje ime</h2>
    <h4>puno ime i prezime</h4>
    <FormInput
      autoFocus
      type="text"
      placeholder="Moje ime..."
      value={value}
      onChange={e => setValue(e.target.value)}
      style={{
        transform: wiggle
          .interpolate({
            range: [0.08, 0.25, 0.41, 0.58, 0.75, 0.92, 1],
            output: [0, -8, 8, -8, -4, 4, 0],
          })
          .interpolate(x => `translate3d(${x}px, 0, 0)`),
      }}
    />
  </>
)

export default Step2
