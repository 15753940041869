export const Trudnoća = [
  {
    title: 'Kako teče razvoj ploda, fetusa?',
    body: `Čovječji plod do desetog se tjedna trudnoće naziva zametkom, a u
  nastavku trudnoće on je fetus. Tijekom prvih pet tjedana traje
  početni razvoj posteljice i zametka. S navršenih šest tjedana ta
  nakupina stanica konačno je oblikovana u zametak. Na njemu se
  razlikuju glavica, trup s kolutičastim razvojnim segmentima i
  postraničnim pupoljcima iz kojih se razvijaju udovi. Veličine je
  zrna kave, a u njegovoj unutrašnjosti kuca malo, nerazvijeno srce.
  Prednji dio trupa ima otvor koji je osnova za razvoj probavnog
  sustava. Na leđnoj strani razabire se uzdužna pruga koja će
  postati kralježnicom. S navršenih sedam tjedana zametak je
  veličine zrna graha. Na glavici su tamne točke koje će biti oči,
  nos, usta. Srce razvija četiri klijetke, a sa strane se mogu
  vidjeti izbočine udova. Zametak se počinje kretati. S osam tjedana
  zametak je veličine 1,5 cm, pliva u šupljini maternice u maloj
  količini plodove vode. Koža je tanka, a kroz nju su vidljive oči,
  dok su niže, bliže vratu, uši. Udovi su razvijeni toliko da možemo
  razabrati nadlakticu, podlakticu, prstiće. Zaključno s dvanaestim
  tjednom zametak će dobiti čovjekoliki izgled.`,
    column: 1,
  },
  {
    title: 'Čemu služi ultrazvuk?',
    body: `Ultrazvuk je uređaj koji odašilje zvučne valove visoke frekvencije
  u vrlo kratkim razmacima, a potom registrira njihov odjek vrlo
  velikom brzinom kako bi stvorio sliku. Ultrazvuk je najkorištenija
  metoda u ginekologiji i porodništvu, a neškodljiv je za pacijente
  i djelatnike.`,
    column: 2,
  },
  {
    title: 'Čemu služi kardiotokografija?',
    body: `Kardiotokografija je metoda kojom se istodobno prate i bilježe
  srčana akcija ploda i trudovi. Mnoga stanja u trudnoći mogu
  ugroziti plod, a kod većine komplikacija smanjuje se opskrba
  fetusa kisikom. Kada je ugrožen, organizam se počinje koristiti
  nekim drugim mehanizmima kako bi nadomjestio nedostatak kisika. U
  tom slučaju se većina protoka krvi u plodu usmjerava prema plodu i
  vitalnim organima. No ako je plod dugo bez kisika, mehanizmi se
  istroše i plod može biti smrtno ugrožen. Jedan od oblika nadzora
  je i CTG uređaj. Normalna srčana frekvencija ploda je između 120 i
  160 otkucaja u minuti.`,
    column: 2,
  },
  {
    title: 'Kako se razvija posteljica?',
    body: `Posteljica je poseban organ koji se razvija tijekom trudnoće na
  mjestu u maternici gdje se ugnjezdilo oplođeno jajašce. U prvim
  tjednima jedna se skupina stanica počinje razvijati u posteljicu.
  Ovaj organ ima funkciju opskrbljivanja djeteta potrebnim tvarima,
  te oslobađanja od suvišnih otpadnih tvari. Tijekom trudnoće
  posteljica obavlja ulogu fetalnih pluća, bubrega i probavnih
  organa. Dijete i posteljica povezani su pupkovinom koja je izvor
  hormona i sličnih tvari koje su odgovorne za normalan tijek i
  razvoj trudnoće. Nakon završetka trudnoće taj organ je odbačen kao
  suvišan.`,
    column: 1,
  },
  {
    title: 'Čemu služe ginekološki pregledi u trudnoći?',
    body: `Prvi pregled posvećen je pregledu vrata maternice. Ukoliko se uoče
  promjene ili ako dugo nije učinjen PAPA test, može se uzeti
  materijal i poslati na analizu. Do dvanaestog tjedna ultrazvučni
  pregled obavlja se transvaginalnom sondom kroz rodnicu. Nakon
  dvanaestog tjedna ultrazvuci se obavljaju preko trbuha. Nakon toga
  slijedi bimanuelna palpacija kojom će liječnik opipati maternicu,
  veličinu, promjene…. Ako je sve u najboljem redu liječnik će
  odrediti pretrage svaka četiri tjedna do 28. tjedna trudnoće. Do
  36. tjedna svaka dva do tri tjedna, od 36. tjedna svakih tjedan
  dana, a nakon 40. tjedna svaki drugi dan.`,
    column: 2,
  },
  {
    title: 'Djeluje li seks u trudnoći u normalnim okolnostima štetno?',
    body: `Snošaj ne može uzrokovati pobačaj niti oštetiti fetus. Ukoliko isti ili drugi dan primijetite malu količinu sukrvice koja ubrzo prestaje ne treba dizati paniku. Na vratu maternice ili u vagini postoje kapilare na kojima mogu nastati sitna oštećenja. Nakon porođaja, kada ste spremni, možete ponovno početi sa seksualnim životom. Tada je ženama potreban nježan partner s puno razumijevanja.`,
    column: 1,
  },
  {
    title: 'Mirisi u trudnoći, da ili ne? ',
    body: `Intenzivni mirisi, mirisi hrane, parfema, ljudi koji se kreću oko nas, mirisi prostora u koje ulazimo … mogu nam smetati i inače, a pogotovo u trudnoći. Mnogobrojna istraživanja pokazala su da je njuh kod trudnica izoštren, a razlog tome može biti povećana razina estrogena u organizmu. Mučnina i osjetljivost na mirise i okuse znak je rasta embrija i posteljice. U većine trudnica ta osjetljivost nestaje negdje oko 13., 14. tjedna trudnoće.`,
    column: 2,
  },
  {
    title: 'Osjećam pojačano lučenje sline, a tek sam na početku trudnoće... ',
    body: `Pojačano lučenje sline bezopasna je pojava za Vas i Vaše dijete. Također, može se raditi i o smanjenom gutanju sline uslijed mučnina. Pomoći si možete unosom hrane koja sadrži škrob.`,
    column: 1,
  },
  {
    title: 'Jutarnje mučnine i zašto do njih dolazi? ',
    body: `Nekoliko je razloga. Jedan od njih su hormoni jer se pod njihovim utjecajem Vaš želudac puno sporije prazni. Utjecaj svakako ima i opuštanje trbušnih mišića. Posteljica i plod proizvode hormon estrogen, a to veliko povećanje vrijednosti također utječe na osjećaj mučnine. Stres u trudnoći, nespavanje, umor, putovanja i hrana mogu biti čimbenici koji uzrokuju mučnine. One će se javiti između 4. i 8. tjedna trudnoće, a završit će otprilike između 13. i 14. tjedna trudnoće. Jutarnja mučnina neće loše utjecati na Vašu bebu, ona je samo znak da Vaša trudnoća dobro napreduje.`,
    column: 2,
  },
  {
    title:
      'Često osjećam da mi je nos začepljen, sluznica iziritirana, a krv iz nosa mi teče dosta često. Je li to normalno?',
    body: `Ove tegobe nastaju zbog povećanog protoka krvi kroz organizam u trudnoći, a kako se sluznica zadebljava dolazi do sužavanja dišnih puteva te je ona sklonija krvarenju. Naravno, tegobe mogu biti i druge prirode - upala sluznice nosa koja je rezultat virusnih i bakterijskih mikroorganizama ili alergijske upale. Kako si pomoći kod začepljenog nosa? Ako niste bolesni i nije u pitanju alergija, može Vam pomoći inhaliranje, ovlaživači zraka te podignuto uzglavlje pri spavanju ili odmaranju.`,
    column: 1,
  },
  {
    title:
      'Kako si pomoći kada mučnine dosegnu svoj vrhunac i kako ih ublažiti? ',
    body: `Svoj želudac ne držite praznim, a ni punim. Jedite pomalo, više obroka tijekom dana. Ujutro, dok se još meškoljite u krevetu, pojedite nekoliko keksa, krekera ili tost, te se dignite kada već započne probava. Pomalo pijuckajte vodu kroz cijeli dan, čak i kada jedete. Jedite hranu koja nije teška, npr. juhicu, krekere, sir, orahe, mlijeko, jogurt…. Izbjegavajte hranu koja vas iritira i izaziva Vam mučninu. Ugljikohidrati kao bijela riža, tost i pečeni krumpir Vam neće škoditi. Masnu hranu izbjegavajte. Prostorije u kojima se nalazite redovito zračite, prošećite po svježem zraku, a ako ste umorni odmarajte.`,
    column: 2,
  },
  {
    title: 'Hrkanje u trudnoći i kako ga eliminirati?',
    body: `Manji broj žena hrče, no u trudnoći je to prilično česta pojava. Ono nastaje zbog sužavanja gornjih dišnih puteva, zbog zadebljanja sluznice. Iako je hrkanje bezazlena pojava, ono može biti uzrok i većih problema ukoliko je povezano s povišenim krvnim tlakom. Žene koje hrču kroz trudnoću imaju povećani rizik od rađanja djece manje porođajne mase. Hrkanje može biti i znak apneje koja uzrokuje kratkotrajni prestanak disanja u snu, a ono uzrokuje manjak kisika u krvi te majku budi iz sna i uzrokuje stres kod djece. Kako si pomoći? Nemojte spavati na leđima već na boku. Prekomjerna težina pospješuje hrkanje stoga se pokušajte kontrolirati. Možda Vam može pomoći flaster za nosnice - on djeluje tako da proširuje ulaz zraka kroz nosnice i tako olakšava disanje, a ne škodi djetetu.`,
    column: 1,
  },
  {
    title: 'Kada potražiti liječničku pomoć?',
    body: `Ako su mučnine jake i izazivaju teške poteškoće svakako je potrebno potražiti pomoć. Ako počnete gubiti na težini, ako sve što pojedete ili popijete momentalno i izbacite iz sebe, ako se osjećate malaksalo, onemoćalo, sve Vas boli ili ako u sadržaju koji povratite primijetite sukrvicu ili krv, tada se svakako javite liječniku.`,
    column: 2,
  },
  {
    title: 'Što ako ne podnosim laktozu? ',
    body: `To ćete primijetiti tako što ćete nakon uzimanja mlijeka imati neke od sljedećih simptoma: vjetrovi, mučnine, mekana stolica, bolovi u trbuhu, napuhanost, višak zraka koji kola vašim crijevima…. Mnoge žene kroz trudnoću puno lakše probavljaju šećer u mlijeku, tj. laktozu. Ženama koje je ipak ne podnose ni kroz trudnoću teško je osigurati pravu dozu kalcija prirodnim putem. Ako ne podnosite mlijeko ili mliječne proizvode evo nekoliko savjeta: uz obrok pokušajte popiti šalicu ili pola šalice mlijeka, odabirite raznoliku hranu bogatu kalcijem (prokulice, špinat, losos, sardine), koristite proizvode na kojima je naglašeno da nemaju laktoze ili da je smanjen sadržaj šećera. Pokušajte konzumirati sireve i jogurte koji se obično bolje podnose od mlijeka.`,
    column: 2,
  },
  {
    title: 'Često me boli glava i osjećam se loše…',
    body: `Rano u trudnoći glavobolju mogu uzrokovati hormonalne promjene i povećan protok krvi. Kasnije kroz trudnoću glavobolja može biti povezana sa stresom, raznim promjenama koje nas prate, umorom, neispavanošću, naprezanjem očiju, napetosti, uznemirenosti… Patite li u normalnom životu od migrena i jakih glavobolja, bolest može ostati ista ili se može pojačati. Kako si pomoći kada nas glavobolja muči iz dana u dan? Uzroci mogu biti različiti, pratite ih. Kada uočite mogući uzrok, prevenirajte glavobolju izbjegavanjem takvih ili sličnih radnji. Odmarajte, spavajte, tuširajte se ili boravite u toploj kupki, opuštajte se, meditirajte, izbjegavajte stresne situacije, stavljajte si tople obloge, iskoristite blagonaklonost drugih i dajte da vas paze, maze, masiraju… Ukoliko se glavobolje ipak nastavljaju i sve su jače i jače, prije uzimanja bilo kakvih lijekova obavezno se obratite liječniku. Liječnik će Vam najbolje savjetovati koje lijekove možete koristiti ili nastaviti koristiti bez obzira što ste trudni. Uz glavobolju mogao bi Vam se mutiti i vid, mogli bi osjećati nagon za povraćanjem. Liječnik će Vam možda savjetovati da neke lijekove izostavite dok bi neke druge mogli početi uzimati.`,
    column: 1,
  },
  {
    title: 'Osjećam konstantnu žgaravicu, kako si pomoći? ',
    body: `Više od polovice trudnica osjetit će žgaravicu većeg ili manjeg intenziteta. Osjetit ćete ju kao žarenje, bol u želucu ili gornjem dijelu trbuha. Možete osjećati napuhanost, višak zraka. Žgaravica je znak da se hrana iz želuca vraća u jednjak. Mišić koji povezuje jednjak i želudac se opušta i propušta kiselinu koja nagriza sluznicu. Hormoni usporavaju rad probavnog sustava, usporavaju se gibanja, a želucu treba više vremena da se isprazni. Tako hranjivi sastojci lakše ulaze u krvotok i hrane našu bebu. Također, naša se maternica povećava i raste, pritišće želudac i pomiče ga prema gore, te tako potiskuje želučanu kiselinu i ona se vraća u jednjak.`,
    column: 2,
  },
  {
    title:
      'Jaka bol u trbuhu i zdjelici tijekom prvog i drugog tromjesečja koja ne prestaje? ',
    body: `Ukoliko je bol u kombinaciji s temperaturom, krvarenjem, jakim iscjetkom, ošamučenošću, bolovima u leđima ili trudovima to može biti znak izvanmaternične trudnoće ili prijevremenog poroda, stoga je preporuka da se hitno javite liječniku.`,
    column: 1,
  },
  {
    title: 'Kako spriječiti i ublažiti žgaravicu? ',
    body: `Postoje mnogi načini da si pomognete ili spriječite žgaravicu: izbjegavajte masnu i pečenu hranu, jedite manje obroke, stalno nešto grickajte. Izbjegavajte čaj, kavu, kiseline, alkohol, pušenje, začinjenu hranu. Pijte puno tekućine, posebno vode. Sokovi i jako slatka pića se ne preporučuju. Ne pušite, cigareta je štetna i za Vas i za dijete. Nemojte se držati zgrčeno, pogrbljeno ili se previše savijati kada dohvaćate stvari. Izbjegavajte položaje koji dodatno pritišću želudac. Nemojte jesti prije spavanja, napravite odmak od barem dva sata. Ležanje na leđima se ne preporuča. Kada ležite, podignite uzglavlje.`,
    column: 2,
  },
  {
    title: 'Primjećujem tamnjenje kože, kako si pomoći? ',
    body: `Hormonalne promjene u trudnoći uzrokovati će tamnjenje kože tijela, pogotovo dijelova kože na bradavicama i dojkama. Nemojte se opterećivati tamnjenjima kože jer ona će proći kada rodite i kada Vam se tijelo počne vračati u normalu.`,
    column: 1,
  },
  {
    title: 'Jesu li valovi topline i znojenje normalni u trudnoći?',
    body: `Ove pojave mogu donijeti neugodan osip na koži. Osip se najčešće pojavljuje na dijelovima tijela koji su prislonjeni jedan na drugi, ispod ruke, ispod grudi, na unutrašnjosti bedara... Svakako se javite liječniku jer će pravodobna reakcija olakšati takve i slične probleme. Kada osip svrbi, pecka ili žari treba ga tretirati i biti će Vam lakše. Ukoliko mu je uzrok znojenje ili vrućina tada nosite prozračnu odjeću od prirodnih materijala, održavajte redovno higijenu, održavajte kožu suhom, tuširajte se mlakom vodom, kožu mažite neutralnim kremama, kupkama i pudrajte mjesto osipa dječjim puderom kako bi koža bila otpornija na trljanja i trenja. Nabrojeno bi trebalo dovesti do olakšanja.`,
    column: 1,
  },
  {
    title:
      'Osjećam veliku želju za određenim kombinacijama namirnica, je li to normalno?',
    body: `Početkom trudnoće mogli bi imati veliku želju za nekom određenom vrstom hrane ili za nekom određenom kombinacijom namirnica. Nemojte se brinuti jer takva je želja uglavnom prolazna. Ovakva iskustva najčešće su uzrokovana hormonalnim promjenama, no nakon trećeg ili četvrtog mjeseca trebala bi prestati. Nemojte misliti da hrana za kojom čeznete ima sastojke koji Vam nedostaju u organizmu, no ukoliko se takva potreba produži, tada postoji mogućnost da vam nedostaje željeza, stoga potražite savjet liječnika.`,
    column: 2,
  },
  {
    title: 'Kako si pomoći kada želja za hranom ne prestaje? ',
    body: `Pokušajte se zadovoljiti zdravim grickalicama od žitarica, bez šećera, ili si jednostavno odvratite pažnju šetnjom, vježbanjem, spavanjem, druženjem ili čitanjem dobre knjige. Dobro si je ponekada i ugoditi ali nemojte to iskorištavati. Nosite se s ovim promjenama na svoj način, ali pripazite da ne ugrozite svoje ili djetetove prehrambene navike, stoga jedite zdravo.`,
    column: 2,
  },
  {
    title: 'Imam problema s hemeroidima, kako si pomoći? ',
    body: `Krvarenje zahtjeva pregled, no ono najčešće dolazi zbog oštećenih hemeroida ili oštećenja stjenke anusa. Oštećenja često nastaju zbog loše probave i zatvora, no nije rijetkost da dođe do zastoja krvi u venskom sustavu koji nastaje pritiskom ploda na velike krvne žile. Kako si pomoći u slučaju zatvora? Jedite lagano, hranu bogatu vlaknima, voće, povrće, manje obroke, pijte puno tekućine, vježbajte… Ako ni onda ne prolazi, obratite se liječniku.`,
    column: 1,
  },
  {
    title: 'Smušena sam, zaboravna, ne snalazim se u svim svojim obvezama… ',
    body: `Ne brinite, to hormoni rade svoje. Možda zaboravite gdje ste stavili ključeve, možda zaboravite na dogovor s prijateljima, možda zaboravite otići na pretragu, to je normalno. Nabavite planer i zapišite obveze, prihvatite da je odsutnost normalna, smanjite stres i napetost.`,
    column: 2,
  },
  {
    title: 'Sukrvica u trudnoći, treba li me biti strah? ',
    body: `Sukrvica se može pojaviti kod većeg broja trudnica. Takva točkasta krvarenja u pravilu nisu razlog za brigu, a mogu biti razlog puknute žilice ili iziritirano tkivo. Da ne biste brinuli možete posjetiti liječnika, ginekologa.`,
    column: 1,
  },
  {
    title: 'Zašto me muči nesanica? ',
    body: `S obzirom na sve tjelesne i emocionalne promjene koje proživljavate kroz trudnoću nije ni čudo da se povremeno ili često dogodi da imate otežano spavanje. To je stanje u kojem imate problema s uspavljivanjem i spavanjem, vrtite se, okrećete, misli vam lete… U početku trudnoće pod utjecajem hormona mogli bi spavati mnogo više nego obično. Kasnije, kako beba raste i  maternica počinje pritiskati mokraćni mjehur, imat ćete veću potrebu za odlaskom na toalet. Nadalje, teško ćete nalaziti ugodan položaj u krevetu. Žgaravica, grčevi, začepljen nos i pomicanje djeteta mogli bi utjecati na Vas i na Vaše spavanje.`,
    column: 2,
  },
  {
    title: 'Kada ću prvi put osjetiti svoje dijete? ',
    body: `Drugo tromjesečje pravo je vrijeme za to. Kod prvorotki prvi će se pokreti osjetiti oko 20. tjedna trudnoće (možda malo prije ili kasnije). Osjetit ćete lagane leptiriće, tapkanje, lagano podrhtavanje… Tijekom vremena koje slijedi pokreti su slabi i rijetki, mogu se pojaviti jednom ili više puta tijekom dana, kasnije postaju sve češći. Lijepo je osjetiti pokrete bebe, tako ćete puno brže i stvarnije osjetiti svoju povezanost s bebom. Kako će trudnoća teći već ćete se naviknuti na ritam vaše bebe. Bebicu ćete najviše osjetiti između 27. i 32. tjedna trudnoće. Pred kraj trudnoće pokreti djeteta će se smanjiti. Ako primijetite da se beba ne miče duže vrijeme i da je ni promjenom položaja ne možete razbuditi, da budete mirni javite se liječniku.`,
    column: 1,
  },
  {
    title: 'Kako si pomoći u slučaju nesanice? ',
    body: `Budite aktivni i vježbajte tijekom dana. Predvečer se okupajte i opustite na svom mjestu za opuštanje. Provjetrite stan i pustite da temperatura bude ugodna, da ne bude previše toplo ni previše hladno. Namjestite se u krevetu pomoću jastuka ili banane za spavanje. Kako trudnoća napreduje biti će Vam lakše spavati na lijevom ili desnom boku. Ukoliko ipak ne možete zaspati, čitajte knjigu, gledajte televiziju, pišite dnevnik trudnoće ili nešto slično.`,
    column: 2,
  },
  {
    title: 'Strah me poroda! ',
    body: `Ako i kada Vas muče razni strahovi vezani za vas, porod, zdravlje vašeg djeteta, majčinstvo sjetite se da je to normalno. Žene, pa i muškarci često strahuju za bebu a da to ne znaju, hoće li znati ako nešto nije u redu, hoće li znati da je porod krenuo, hoće li znati kada u bolnicu. Hoće li se snaći među nepoznatim ljudima u bolnici, hoće li završiti na carskom rezu, hoće li…, hoće li… Tu će mamama i tatama pomoći edukacije, znanje i informiranost o svemu što slijedi. Upišite tečaj, radionicu, uzmite doulu, pitajte liječnika… Samo se informirajte i već će vam biti lakše. Za početak sjednite i u svoj dnevnik trudnoće upišite svoje strahove, sljedeći dan dodajte što ste zaboravili. Nazovite prijateljicu, pozovite svog partnera ili kontaktirajte svoju doulu i razgovarajte o svojim strahovima. Iznesite razloge zašto se tako osjećate. Razgovorom ćete izbaciti negativne misli i odmah će Vam biti malo lakše, Vaša doula dat će Vam odgovor na sva Vaša pitanja i dileme, a na zdravstvena pitanja odgovorit će Vaš liječnik. Nakon toga će Vam svakako biti puno, puno lakše.`,
    column: 1,
  },
  {
    title: 'Osjećam se kao balon, što učiniti?',
    body: `Usporavanje probave u trudnoći, mučnina i gutanje većih količina zraka nego inače može uzrokovati napuhanost i vjetrove. Kako bi spriječili ovakve i slične pojave pijte dosta tekućine, budite aktivni i jedite raznovrsnu hranu punu vlakana. Jedite manje, a češće, polako i bez žurbe. Izbjegavajte hranu koja Vas inače napuhuje.`,
    column: 2,
  },
  {
    title: 'Cijela sam zadebljana i otečena, kako si pomoći?',
    body: `Kroz trudnoću, a pogotovo u trećem tromjesečju, mogli bi primijetiti oticanje pojedinih dijelova tijela. Najprije ćete primijetiti da se to dogodilo na licu, vjeđama, oko očiju… Otekline su normalna pojava u trudnoći, a nastaju zbog povećanog protoka krvi kroz organizam i nakupljanja tekućine u mekim tkivima. Oko zglobova ruku i nogu te na drugim mjestima na tijelu mogli bi se pojaviti edemi. Oni znaju biti neugodni jer nekada ne stanu u odjeću i obuću te ne izgledaju prirodno ni lijepo. Mogu vam pomoći hladni oblozi, boravak u rashlađenim prostorijama, dizanje udova na povišeno te namakanje u hladnijoj vodi.`,
    column: 2,
  },
  {
    title: 'Je li kroz trudnoću potrebna pojačana briga za zube? ',
    body: `Kroz trudnoću posjetite svog stomatologa i zatražite savjete oko brige za zube. Često čujemo da su žene u trudnoći sklonije problemima sa zubima i zubnim mesom. Problemi sa zubima najčešće dolaze od većeg nakupljanja bakterija u usnoj šupljini i stvaranja zubnog kamenca. Ako dođe do taloženja i stvaranja bakterija mogli bi dobiti bakterijske džepove između zubnog mesa i zubi. Desni bi vam mogle biti crvene, nadražene, osjetljive, krvariti. To zna biti prilično neugodno i može trajati. Vodite brigu o svojim zubima jer njihovo zanemarivanje može dovesti do ozbiljnijih posljedica.`,
    column: 1,
  },
  {
    title: 'Izlaganje suncu, da ili ne? ',
    body: `Znamo da postoje razne blagodati izlaganja sunčevim zrakama poput boljeg raspoloženja, boljeg psihičko stanje, proizvodnje vitamina D i preplanulosti. No postoji još više opasnosti koje donosi izlaganje suncu i njegovim zrakama. Mnogi liječnici tvrde kako ne postoji zdravo izlaganje suncu. Ultraljubičasto zračenje sunca, solarija ili UV svjetiljki izaziva rak kože, oštećenja tkiva, starenje kože. Kreme i preparati za tamnjenje također nisu dobri za Vas, a oni za zaštitu od sunca nisu dovoljno istraženi. Kako se onda zaštititi? Ne izlazite na sunce između 10 i 16 sati, zaštitite se odjećom, potražite mjesto u hladu, pijte dovoljno vode…`,
    column: 2,
  },
  {
    title: 'Kako njegovati zube u trudnoći?',
    body: `Posjećujte češće stomatologa. Zube perite ujutro i navečer zubnom pastom s dodatkom fluora. Kad četkate zube, četkajte i jezik. Koristite zubni konac da ne bi došlo do nakupljanja bakterija na hrani između zubi. Za održavanje zdravlja i kvalitete zubi važno je da se pravilno i zdravo hranite.  `,
    column: 1,
  },
  {
    title: 'Imam sve više problema s kožom lica. Kako si pomoći?  ',
    body: `Koža pod utjecajem hormona izlučuje masti iz žlijezda pa možete dobiti akne. Zato koristite kvalitetnu kozmetiku i pazite što stavljate na lice. Ne preporuča se prekrivanje akni raznim sredstvima, struganje kože, maske ili bilo koja druga agresivnija sredstva za kožu i kosu.`,
    column: 2,
  },
  {
    title: 'Stalno sam žedna...  ',
    body: `Žeđ je normalna u trudnoći, dodatni unos tekućine služi za održavanje plodne vode i povećanje volumena krvi. Pomaže u vlaženju kože, prevenciji zatvora, pravilnom funkcioniranju bubrega i uklanjanju štetnih tvari. Ukoliko niste ljubiteljica obične i mineralne vode, tekućinu slobodno nadoknadite prirodnim voćnim sokovima, sokovima od povrća, juhom ili mlijekom.`,
    column: 1,
  },
  {
    title: 'Primjećujem bijelu liniju na trbuhu... ',
    body: `Zbog djelovanja hormona na vaše tijelo jedva primjetna blijeda linija koja se proteže od pupka do stidne kosti u trudnoći obično potamni - kod nekih mama jedva primjetno, no kod nekih ona zna biti vrlo izražena i tamna. Njeno nastajanje ne možete spriječiti jer ona postoji, ali i ako Vas smeta, ona je samo privremeno tu, izblijedit će i nestati odmah nakon porođaja. Trudnoća ne donosi samo negativne promjene na vašoj koži, ona donosi i onaj prekrasan sjaj koji samo trudnice imaju.`,
    column: 2,
  },
  {
    title: 'Osjećam pritisak na trbušnu stijenku... ',
    body: `To je normalno ukoliko nemate i neke druge simptome koji mogu utjecati na osjećaj pritiska. Kako Vaše dijete raste i Vaša maternica se povećava, ona radi pritisak na unutarnje organe te Vi osjećate manji ili veći pritisak na donjem dijelu trbuha. Pritisak možete osjećati već u prvom tromjesečju dok je on u drugom i trećem sve više prisutan kako Vaša beba raste. Kako se maternica povećava, ona pritišće mokraćni mjehur i debelo crijevo, što Vam daje veću potrebu za odlaskom na toalet. Znači - nemojte se bojati, to je normalno.`,
    column: 1,
  },
  {
    title: 'Imam melasmu... ',
    body: `Fleke su česte u trudnica, a posebno često se pojavljuju kod tamnoputih i tamnokosih žena. Melasma se pojavljuje na dijelovima tijela koji su jače izloženi suncu, najčešće na licu - čelo, obrazi, brada, nos, gornja usna…. Pojavljuje se na obje strane lica, a nakon porođaja melasma može, a i ne mora, u potpunosti nestati.`,
    column: 2,
  },
  {
    title:
      'Što kada osjećam pritisak u donjem dijelu trbuha, on dugo traje i prate ga drugi simptomi?',
    body: `Liječniku se trebate javiti ukoliko osjećate jaku bol, grčeve, trudove, imate vodenasti iscjedak ili mislite da vam je pukao vodenjak. Sve to može biti znak pobačaja, izvanmaternične trudnoće, prijevremenog porođaja i slično - bolje se javiti liječniku nego čekati da se smiri.`,
    column: 1,
  },
  {
    title: 'Koža mi je puna čvorića i malih kožnih privjesaka.',
    body: `Te male kožne tvorbe su bezopasne, ali ne izgledaju lijepo, pogotovo ako ih je puno. Nije poznato zašto nastaju ali ih je moguće jednostavno ukloniti. Samo se posavjetujte sa svojim liječnikom. Utješit ću Vas ako kažem da ih uglavnom vidimo samo mi same i da nestaju nakon porođaja.`,
    column: 2,
  },
  {
    title:
      'Koje posljedice može donijeti blaža bol u predjelu trbuha u vrijeme prvog i drugog tromjesečja? ',
    body: `Rastezanje ligamenata i mišića može uzrokovati blažu bol, probadanje, grčeve i sl. Još jedan razlog tome može biti rastezanje obloga ligamenata koji pridržavaju maternicu. Blaža bol koja se pojavljuje s vremena na vrijeme i prolazi nije nešto oko čega se trebate brinuti, no ako je redovita i ne prestaje može se razviti u početak porođaja iako niste ni blizu termina. Ako Vam bol stvara nelagodu pokušajte leći, sjesti, opustiti se u toploj vodi, ili se konzultirajte s liječnikom.`,
    column: 1,
  },
  {
    title: 'Pojačana pigmentacija - moraju li je dobiti sve trudnice? ',
    body: `Uglavnom da, a to ćete primijetiti i na bradavicama koje će potamniti u cijelom dijelu ili samo u nekim dijelovima. Sredstva koja bi izbijelila kožu na potamnjelim dijelovima izbjegavajte jer nisu dobra ni za Vas ni za Vašu bebu. Nakon porođaja tamnija pigmentacija se gubi, tako da budite mirni i strpljivi.`,
    column: 2,
  },
  {
    title: 'Osjećam svrbež i nelagodu… ',
    body: `Jedna petina trudnica kroz trudnoću osjeti svrbež na predjelu trbuha i cijelog tijela. To se najčešće događa prilikom pretjeranog rastezanja kože. Koža crveni, ljuska se, stvaraju se čvorići, pločaste tvorbe… Stoga posebno kroz trudnoću svoju kožu i svoje tijelo treba posebno njegovati. Iako rijetko, može se dogoditi da trudnice imaju zastoj žući, odnosno kolestazu, koja se vidi po jakom svrbežu po cijelom tijelu. Tada se svakako trebate javiti svom liječniku.`,
    column: 1,
  },
  {
    title: 'Putovanje u trudnoći - da ili ne?',
    body: `Najbolje vrijeme za putovanje tijekom trudnoće je drugo tromjesečje. Manji je rizik od pobačaja ili prijevremenog pobačaja, tijelo se prilagodilo promjenama kroz trudnoću, nemate više mučnine i vrtoglavice i još uvijek ste dovoljno vitalni i možete se kretati bez većih poteškoća. Ukoliko pak imate većih poteškoća ili komplikacija u trudnoći kao što su bolesti srca, krvnih žila ili ste imali poteškoće u prijašnjim trudnoćama, najbolje je da se o svemu savjetujete sa liječnikom. Ako ste se odlučili za put i putujete automobilom evo nekoliko smjernica koje će vam olakšati put… Budite vezani tijekom vožnje, postoji pravilan način vezanja pojasa a to je tako da jedna traka prelazi koso iznad vašeg trbuha, ona se u donjem dijelu nalazi sa strane uz trbuh i putuje u gornjem dijelu između vaših dojki, a druga iznad vaših bedara. S vremena na vrijeme zaustavljajte se tijekom vožnje kako bi se uspravili, istegnuli i kako bi Vam vožnja bila lakša i ugodnija. Ograničite vrijeme u automobilu na maksimalno šest sati. Kada stanete, posjetite toalet i lagano prošećite.`,
    column: 2,
  },
  {
    title: 'Kako si pomoći u slučaju svrbeži? ',
    body: `Nosite odjeću od laganih i prirodnih materijala, održavajte prostore u kojima se nalazite provjetrenima i ne pretoplima. Kožu tijela mažite nježnim losionima, kremama i uljima. Ukoliko sami ne uspijete riješiti problem svrbeža, javite se liječniku koji će vam prepisati terapiju.`,
    column: 1,
  },
  {
    title: 'Je li konzumacija alkohola u trudnoći prihvatljiva? ',
    body: `Nemojte popiti niti jedno piće, jer ono bi moglo štetno utjecati na Vas, a kroz Vas i na Vaše nerođeno dijete. Činjenica da ste u ranoj trudnoći popili čašicu ili dvije jer niste znali da ste trudni neće učiniti veću štetu, ali prestanite s ispijanjem alkohola u većoj ili manjoj količini čim saznate da ste trudni.`,
    column: 1,
  },
  {
    title: 'Putovanje brodom, da ili ne?',
    body: `Kada šećete brodom budite oprezni i stabilni. Ukoliko je more nemirno obavezno se pridržavajte da ne bi izgubili ravnotežu i pali jer more zna biti varljivo. Većina putničkih brodova prima žene kroz trudnoću. Morate biti svjesni toga da na brodu i osobama koje nisu trudne vrlo često zna biti loše i znaju se mučiti s mučninama i vrtoglavicom. Zato, pažljivo.`,
    column: 2,
  },
  {
    title: 'Preuređenje doma u trudnoći, da ili ne? ',
    body: `Ukoliko tijekom trudnoće odlučite preurediti stan, ličiti zidove, obojati dio namještaja, obojati krevetić ili neki drugi dio namještaja za dijete - činite to oprezno. Pametnije, zamolite nekog drugog da to učini umjesto Vas. Tijekom trudnoće, a pogotovo tijekom prvog tromjesečja, izbjegavajte izlaganje uljnim bojama, olovu, kromu i razrjeđivačima koji se nalaze u nekim bojama. Sva navedena sredstva mogu izazvati negativne posljedice za plod. Istraživanja su pokazala da navedena sredstva štetno utječu na trudnoću, pogotovo ako je žena trajno izložena parama. Ukoliko se pak odlučite na ovu vrstu posla - stavite masku, provjetravajte prostor u kojem radite, obojite predmet na otvorenom, te nemojte jesti ni piti u prostoru u kojem radite.`,
    column: 1,
  },
  {
    title: 'Putovanje zrakoplovom, da ili ne? ',
    body: `Za početak se raspitajte o ograničenjima za trudnice u kompaniji s kojom planirate putovati (u nekima je to 35., a u nekima 36. tjedan trudnoće). Trebalo bi sa sobom ponijeti potvrdu liječnika da možete putovati. Kako bi Vam u avionu bilo što ugodnije, pokušajte učiniti sljedeće: pokušajte odabrati sjedalo uz prolaz ili na mjestu gdje ima više prostora, vežite se sigurnosnim pojasom kada god možete da vaša vožnja bude što sigurnija, pijte dovoljno tekućine i prije i za vrijeme leta. Kada je moguće šećite i krećite se, pogotovo ako se radi o dugom letu.`,
    column: 2,
  },
  {
    title: 'Kako si pomoći kod gubitka trudnoće?',
    body: `Bez obzira na njeno trajanje, gubitak trudnoće izrazito je teško iskustvo za oba roditelja. Mogli biste se osjećati prazno i izgubljeno. Pokušajte prebroditi ovaj teški period uz pomoć partnera, roditelja, prijatelja ili psihologa ili psihijatra. Loš ishod prethodne trudnoće ne znači da nećete moći imati drugu bebu ili da nešto u sljedećoj mora krenuti po zlu. Pravi trenutak za pokušati ponovno ovisi o vašem tjelesnom i emocionalnom oporavku. Liječnici preporučaju razdoblje čekanja od 2-3 mjeseca.`,
    column: 1,
  },
  {
    title: 'Vrtim se cijelu noć i imam intenzivne snove…',
    body: `Nemirni snovi, noćne more i aktivnosti u snu normalni su kroz trudnoću. U vrijeme velikih promjena u Vašem životu snovi Vam se mogu činiti kao jako intenzivni i čudni, no oni su normalni. Vaš um će tijekom noći obrađivati informacije, one svjesne i one nesvjesne. Vaši snovi biti će odraz Vaših doživljaja i uznemirenosti oko važnih događaja koji Vas svakodnevno prate i koji će se događati u razdoblju koje slijedi. `,
    column: 2,
  },
  {
    title: 'Nenormalno se znojim, je li to normalno? ',
    body: `To je normalno. Uslijed utjecaja hormona na žlijezde lojnice mogli bi primijetiti da Vas s vremena na vrijeme oblijeva znoj, što se prije nije događalo. Osim hormona, utjecaj ima i otpuštanje topline od ploda u maternici. Uz znojenje bi mogli primijetiti i osip, posebno na pregibima. Kako si pomoći? Unosite dovoljno vode i soli u organizam da ne dođe do dehidracije. Odmarajte se, boravite u rashlađenim prostorijama, budite u hladu i izbjegavajte sunce, nosite prozračnu odjeću i obuću.`,
    column: 1,
  },
  {
    title: 'Vrti mi se i ne osjećam se dobro.',
    body: `Ova stanja mogu nastati zbog promjena u krvotoku poradi pritiska maternice na krvne žile u leđima i zdjelici. Česta pojava u ranoj trudnoći može biti nizak šećer u krvi te premalo crvenih krvnih zrnaca. Posebno teško bi Vam moglo biti u drugom tromjesečju kada su krvne žile proširene zbog djelovanja hormona, a volumen krvi još nije dovoljno povećan. I na kraju - umor, glad, stres, šećerna bolest, tlak - mogu dovesti do nesvjestice ili vrtoglavice.`,
    column: 2,
  },
  {
    title: 'Ponekad ne vidim stepenicu ili se spotaknem... ',
    body: `Ako se osjećate nespretno ili neusredotočeno, pripazite! No ukoliko slučajno i dođe do pada i uhvati Vas panika, svakako imajte na umu da se na početku trudnoće maternica nalazi iza zdjelične kosti i potpuno je zaštićena, a kroz nastavak trudnoće Vaše je tijelo tako koncipirano da zaštiti dijete u Vama. Zidove maternice štite čvrsti mišići, a plodova voda oblaže dijete poput jastuka sa svih strana. Ozljeda bi trebala biti toliko ozbiljna da prvo jako našteti Vama, pa tek onda djetetu. No ukoliko imate osjećaj da s djetetom nešto nije u redu -svakako posjetite svog liječnika.`,
    column: 2,
  },
  {
    title: 'Primijetila sam žarko crvenilo na dlanovima, što da radim? ',
    body: `Veći broj trudnica kroz trudnoću primijetiti će crvenilo stopala i crvenilo dlanova, što je vrlo česta promjena koja se događa trudnicama, a izraženija je kod žena bijele puti. Nastaje zbog povećanog protoka krvi kroz kožu, a prati ga svrbež i nelagoda. Nelagodu si možete olakšati njegovanjem laganim kremama i losionima. Kao i većina promjena koje se pojavljuju na koži tijekom trudnoće i ova će se maknuti u kratkom vremenu nakon poroda. Ukoliko se nakon poroda crvenilo ipak ne izgubi, javite se liječniku jer uzrok mu mogu biti i neke ozbiljne bolesti.`,
    column: 1,
  },
  {
    title: 'Boli me trbuh, imam grčeve, osjećam bol u leđima…',
    body: `Ako se grčevi i bolovi pojavljuju u ranoj trudnoći oni mogu biti opasni te nam mogu nam dati naznake da je došlo do pobačaja ili izvanmaternične trudnoće - stoga se javite liječniku da utvrdite svoje stanje. Grčevi i bolovi u daljnjem tijeku trudnoće mogu biti znak da je porod prijevremeno krenuo. Jaka, iznenadna, postojana bol može biti i znak da se posteljica počela odvajati. Ako imate mučninu, glavobolju i osjećate se izrazito loše, ako se pojavljuje iscjedak iz rodnice, imate temperaturu - sve to može biti znak infekcije te se i u ovom slučaju potrebno javiti liječniku.`,
    column: 1,
  },
  {
    title: 'Stalno sam umorna, je li to normalno?',
    body: `Kroz cijelu trudnoću Vaše tijelo dodatno radi. Na samom početku proizvodi hormone i dodatnu količinu krvi koja opskrbljuje dijete hranom. Pojačava se rad srca kako bi se prilagodilo ubrzanom protoku krvi. Mijenja se način iskorištavanja vode, bjelančevina, masnoće, ugljikohidrata. Velika razina progesterona čini Vas pospanom. Morate računati i s tim da kroz cijelu trudnoću nosite i dijete. Odmarajte se kada osjetite da Vam je odmor potreban. Sve dodatne obveze pokušajte svesti na minimum. Vježbajte i gibajte se koliko možete. Hranite se pravilno, a ako ne znate kako, potražite savjet nutricionista ili se sami educirajte o zdravoj prehrani.`,
    column: 2,
  },
  {
    title: 'Vrlo često, ponekad i svakodnevno, osjećam mučninu i trbobolju.',
    body: `I kroz trudnoću majke mogu imati želučanih teškoća. Ukoliko pojavama kao što su mučnina, proljev i povraćanje izgubite puno tekućine, primijetit ćete neke od sljedećih pojava: biti će Vam slabo, loše ćete se osjećati, osjećat ćete žeđ, usta će vam se sušiti, mokraća će biti gušća i tamnija. To su sve znakovi da tekućinu treba nadoknaditi. Kako bi se zaštitili od ove vrste obolijevanja perite češće ruke, izbjegavajte kontakt s bolesnim osobama, ne dijelite čaše ili jedaći pribor s drugima. Pripremajte hranu kako treba jer to je najčešći razlog želučanih i probavnih problema.`,
    column: 1,
  },
  {
    title: 'Pospremanje kuće u danima prije poroda, da ili ne? ',
    body: `Kako se približavate terminu poroda, tako počinjete osjećati veliku želju da svoju kuću prilagodite i pripremite za dolazak prinove. Najčešće takvu pojavu nazivaju gniježđenjem i ona je potpuno normalna u danima prije poroda. Kako se ne bi pretjerano umarali i trošili energiju, predlažem da većinu priprema i kupnji obavite ranije, a u samom finišu obavite tek preostale sitnice. Ukoliko imate mogućnost, organizirajte za sebe svu potrebnu pomoć za dane nakon poroda. Sretno!`,
    column: 2,
  },
  {
    title: 'Što je o sluzni čep? ',
    body: `Nekoliko dana (nekad i tjedana) prije poroda, vrat maternice će se stanjivati i omekšavati, a sluzni čep će iskliznuti kroz rodnicu (ponekad u komadićima, a ponekad zaista kao čep). Sluzni čep može biti prošaran žilicama ili može izaći uz blagu sukrvicu, no neka Vas on ne brine. Održavajte svakodnevnu higijenu, koristite higijenske uloške i nosite prozračno donje rublje.`,
    column: 2,
  },
  {
    title: 'Oči su mi suhe, grebu, peku, mogu li si nekako pomoći?',
    body: `Kako na tijelu, tako i na očima trudnice kroz trudnoću, može doći do određenih promjena. Vanjski sloj oka postaje deblji, a tlak tekućine unutar oka postaje manji. Mogli bi primijetiti da vam se magli vid, da su vam oči suhe i otečene. Pratite što se događa s Vašim vidom kroz trudnoću -promjene bi mogle ukazivati na komplikacije šećerne bolesti, visoki krvni tlak ili neke druge probleme.`,
    column: 1,
  },
  {
    title: 'Iscjedak je promijenio boju… ',
    body: `Ukoliko iscjedak promijeni boju ili poprimi neugodan miris, ukoliko osjetite bol, svrbež ili nelagodu, to može značiti da ste dobili infekciju. Kako bi vidjeli što se događa i kako ne bi brinuli, posjetite liječnika. Pojava iscjetka može biti i znak prijevremenog poroda, tako da obavezno potražite liječničku pomoć.`,
    column: 2,
  },
  {
    title: 'Konjunktivitis ili nešto drugo? ',
    body: `Kako na tijelu, tako i na očima trudnice kroz trudnoću, može doći do određenih promjena. Vanjski sloj oka postaje deblji, a tlak tekućine unutar oka postaje manji. Mogli bi primijetiti da vam se magli vid, da su vam oči suhe i otečene. Pratite što se događa s Vašim vidom kroz trudnoću -promjene bi mogle ukazivati na komplikacije šećerne bolesti, visoki krvni tlak ili neke druge probleme.`,
    column: 1,
  },
  {
    title: 'Primijetila sam iscjedak iz dojki, što da radim? ',
    body: `U početku će on biti izrazito žute boje, a moći ćete ga primijetiti u obliku mrlja na majici ili sitnih sasušenih mrvica u Vašoj odjeći. Neka Vas on ne brine – iscjedak je priprema Vaših dojki za dojenje. Neka Vas ne brine ni ako ga ne primijetite, mlijeko – ili, u ovom slučaju, kolostrum – se može pojaviti i nakon samog poroda. Kako bi si pomogli, možete kupiti i nositi jednokratne jastučiće koji će Vam također pomoći i nakon poroda (dok se mlijeko ne regulira i ne počne se stvarati u količinama potrebnim za Vašu bebu).`,
    column: 2,
  },
  {
    title: 'Imam išijas, kako si pomoći? ',
    body: `Osjećate li bol, utrnulost, toplinu i žarenje koji se protežu niz leđa i stražnjicu pa sve do nogu, ta pojava naziva se išijas. Iako ga žene imaju i kada nisu trudne, u trudnoći on zna biti posebno prisutan jer maternica i dijete rade pritisak na živce. Vaše stanje mogu pogoršati sagibanje, pregibanje, nagli pokreti, ustajanje, podizanje i nošenje tereta. Išijas je neugodan ali nije opasan za dijete. Nestat će kad se dijete počne spuštati u položaj za porod. Kako si pomoći? Pri spavanju najbolje će Vam pomoći poznati banana jastuk kako bi se namjestili upravo onako kako Vam odgovara. Ako vam odgovaraju, tople kupke i oblozi također mogu biti od velike pomoći. Ustajte i sjedajte polako te polako radite promjene pokreta.`,
    column: 1,
  },
  {
    title: 'Trebamo li se educirati o porodu? ',
    body: `Neupućenost u proces rađanja, strah, napetost i bol najveće su smetnje u ostvarenju opuštenog i sretnog poroda kakav svaka od nas priželjkuje. Tehnike opuštanja i disanja mogu Vam pomoći da izbacite napetost iz svog duha i svog tijela. Kako je opuštanje tehnika koju možete savladati i naučiti, uključite se u neki od tečajeva ili radionica za pripremu trudnica i parova za porod i vježbajte. Nađite mirno mjesto u svom domu, udobno se smjestite i dajte si vremena. Ugodna glazba, prigušeno svjetlo i meditacija mogu Vam pomoći u tome. Dišite duboko i polako, a sa svakim novim udisajem i izdisajem otpuštajte napetost iz svog tijela. Posebnu pažnju obratite na opuštanje mišića lica i ruku. Ono što ste naučili ubrzo ćete moći primijeniti i na porodu. Pokušajte se opustiti koliko možete, fokusirajte se na disanje i dišite kroz svaki trud. U tome Vam može pomoći i vaša pratnja dajući vam lagane upute i dišući s vama. Vaša pratnja pomoći će i laganim pritiskom određenih dijelova tijela ili laganom masažom leđa, ramena, ruku, nogu, stopala... Sretno!`,
    column: 2,
  },
  {
    title: 'Stalno me bole kukovi. Što da radim? ',
    body: `Ligamenti se lagano rastežu i zglobovi popuštaju. No što je veća opuštenost, to ćete (kada za to dođe vrijeme) lakše roditi. Vježbanje kroz trudnoću svakako Vam može pomoći. Pomoći Vam mogu i tople kupke, oblozi i masaže.`,
    column: 1,
  },
  {
    title: 'Boli me ruka, kao da sam je istegnula…',
    body: `Sindrom karpalnog kanala čest je u trudnica - on se manifestira slabosti, trncima, žarenjem ili bolovima u rukama. Najčešće se pojavljuje u obje ruke, a često je uzrokovan nizom jednakih pokreta. Ako se pojavi, zamolite partnera da vam masira ruke, razgibavajte ih u jednom ili drugom smjeru i smanjite radnje koje ga možda potenciraju. U većini slučajeva ovaj sindrom nestaje nakon porođaja. Ukoliko se to i ne dogodi, postoje rješenja i svakako se obratite svom liječniku.`,
    column: 1,
  },
  {
    title: 'Što mi najviše može pomoći kako bi porod bio opušten i lagan? ',
    body: `Jedna od najvažnijih stvari u pripremi za porod i na samom porodu je pozitivan stav. Pozitivan stav kroz trudnoću, pozitivan stav o trudovima, pozitivan stav o porodu i o danima majčinstva. Vaša Doula proći će s Vama gotovo sve situacije u koje možete doći, kroz sve strahove i nedoumice. Pripremite li se kvalitetno, ništa Vam neće biti strano ni nepoznato. Kako sami sebi možete pomoći? Ohrabrujte se, na porod povedite pratnju po svom izboru. Pratnja će Vas također podržati u Vašim pozitivnim mislima. Razgovarajte, smijte se, opustite se koliko god možete, krećite se, gibajte se i nigdje ne žurite. Kroz porod možete slušati meditacije ili tihu glazbu. Mislima možete otići u svoj svijet, možete maštati i zamisliti da ste na nekom lijepom, stvarnom ili zamišljenom, mjestu, među sebi dragim ljudima. Možete se prepustiti nečem što samo Vama odgovara. Neke od mama bile su ribe, kitovi, meduze, srne, neke su u mislima trčale nepreglednim poljima ili ležale u klasju, neke su letjele. Možete si pomoći na bezbroj načina, samo trebate pronaći onaj koji Vama najbolje odgovara.`,
    column: 2,
  },
  {
    title: 'Boli me tanka kožica u području pupka...',
    body: `Ova tupa bol i napetost uzrokovana je povećanjem maternice u trudnoći, a može biti izražena od 20. tjedna trudnoće nadalje. Simptomi su najizražajniji u sjedećem položaju. Kako bi si pomogli, možete stavljati obloge na mjesto zatezanja ili prstima masirati napeto područje. Ukoliko gubite apetit, loše se osjećate, pritisak je vrlo jak i neugodan, zbog svoje sigurnosti javite se liječniku.`,
    column: 1,
  },
  {
    title:
      'Osjećam pritisak na pojedine organe i udove u tijelu, kako si pomoći? ',
    body: `Kod trudnica su česti grčevi u mišićima potkoljenice, a oni su najčešće uvjetovani usporenom cirkulacijom, prenaprezanjem i pritiskom naše otežale maternice na živce nogu. Kako si pomoći? Nosite ugodnu i ravnu obuću, hodajte i gibajte se, budite aktivni, stavljajte obloge ukoliko Vas bole noge, rastežite mišić, masirajte listove (ili još bolje, neka Vas drugi masiraju), dignite noge na povišeno, ako puno stojite stavite deblje čarape(kupuju se u specijaliziranim ljekarnama). Ako ništa od navedenoga ne pomaže, javite se za savjet liječniku.`,
    column: 1,
  },
  {
    title: 'Kako će porod krenuti? ',
    body: `Jednog dana mogli bi se početi osjećati neobično. Vaši trudovi neće se pojaviti i nestati, već će se nastaviti dalje u jačem ili slabijem ritmu. Osjetit ćete povremena stezanja i opuštanja maternice koja će se ponavljati. Trudovi će trajati od 15 do 30 sekundi i biti nepravilni - na 30 min., 10 min., 3 min., pa ponovno ispočetka. Tijekom porođaja maternica će se stezati, a vrat maternice će postajati tanji i širi. U početku trudovi mogu biti gotovo bezbolni, no njihovim razvijanjem oni će postati neugodni i bolni. Bol će jačati i postajati intenzivnija, punija, čvršća.`,
    column: 2,
  },
  {
    title: 'Osjećam pritisak na rebra… ',
    body: `U posljednjim mjesecima trudnoće majke često ukazuju na bol u predjelu rebara. Tada je djetešce dovoljno veliko da svojim nožicama napravi pritisak u području majčinih rebara i izazove veliku nelagodu, a ponekad i bol. Uz pritisak dolazi i do mijenjanja oblika prsnog koša majke, otežano je disanje i rastezanje grudnog koša. Ako se pojavi velika bol i pritisak pokušajte promijeniti položaj, podignuti ruke iznad glave ili nježno potisnuti dječja stopala prema dolje. Bol i nelagoda će nestati kada se dijete spusti u zdjelicu. Kod majki prvorotki to će se dogoditi dva do tri tjedna prije porođaja, a kod majki višerotki neposredno pred sam porođaj.`,
    column: 1,
  },
  {
    title: 'Kako raspoznati lažne od pravih trudova? ',
    body: `Lažni trudovi su nepravilni i ne jačaju, dok pravi trudovi imaju svoj ritam i traju. Ukoliko se krećemo i mijenjamo položaje lažni trudovi će stati, a pravi će se nastaviti i jačati. Pravi trudovi bit će s vremenom sve dulji, a lažni će jenjavati. Kretanje pravih trudova osjećat ćete od leđa prema trbuhu i njegovom gornjem dijelu, dok se lažni trudovi zadržavaju u donjem dijelu trbuha.`,
    column: 2,
  },
  {
    title: 'Imam osjećaj da je dijete toliko nisko da ću se dolje raspuknuti… ',
    body: `Kako se trudnoća bliži svom kraju, dijete će se u maternici smjestiti u zdjelični prostor i raditi sve veći pritisak na zdjelicu. Tada bi mogli osjećati bol i pritisak u predjelu međice. Ukoliko ste prvi put trudni, bol se može pojaviti nekoliko tjedana prije poroda. Ako ste višerotka, bol se može pojaviti nekoliko dana prije poroda. Uz bol, može se pojaviti i napetost koju dijete radi u trenucima kada glavica pritišće kosti zdjelice.`,
    column: 1,
  },
  {
    title: 'Kako si pomoći kod lažnih i pravih trudova?',
    body: `Hodajte, krećite se, ako Vam paše voda - tuširajte se. Povedite nekoga sa sobom u šetnju, kada dođe trud zastanite, a kada prestane krenite dalje. Ljuljuškajte se, ulovite neki svoj ritam. Topli ili hladni oblozi također mogu pomoći. Nađite svoje mirno mjesto, ušuškajte se, utoplite, pokušajte se maksimalno opustiti. No ne zaboravite gledati na sat i pratiti vrijeme između i za vrijeme trudova te na vrijeme krenuti u rodilište.`,
    column: 2,
  },
  {
    title: 'Kada će prestati otežano disanje, pritisak i loša probava?',
    body: `Kako se približavate terminu poroda Vaše teškoće mogle bi se smanjiti ili u potpunosti nestati. U prvorotki ova pojava može se dogoditi već nekoliko tjedana prije poroda, no kod mama koje su već rodile najčešće se događa pred sam porod. Dijete će se smještati sve dublje u zdjelicu, njegov pritisak na želudac i ošit će nestati, probava će se normalizirati i osjećati ćete se bolje. Vaš trbušćić će biti sve niže i sve više izbačen prema naprijed. Tada bi mogle nastupiti neke druge teškoće jer je beba nisko u zdjelici i počinje pritiskati mjehur, stoga bi mogli morati malo češće na toalet, mogli bi osjećati pritisak i bol u području prepona, oštro probadanje u rodnici i oko nje te bolove. Ukoliko počnete osjećati i druge znakove početka poroda, javite se liječniku. Odmarajte kada osjetite umor te legnite i odmorite onda kada je pritisak prevelik.`,
    column: 1,
  },
  {
    title: 'Kako ću znati jesam li otvorena? ',
    body: `Ako Vam je prvi porod i niste sigurni u sebe i svoju prosudbu uputite se svom liječniku koji će utvrditi jeste li se otvorili ili ne. Početak poroda drugačiji je kod svake žene. Neke žene će osjećati tek laganu bol i pritisak, a već će biti nekoliko prstiju otvorene. Neke će osjećati bolove i stezanja, a imati će samo lažne trudove. Neke će doći na pregled i liječnik će utvrditi da je porod započeo, a neke će prespavati veliki dio poroda. Normalno je da trudovi traju satima, no ako oni i nastave i postaju sve jači i intenzivniji na dobrom ste putu da postanete mama.`,
    column: 2,
  },
  {
    title: 'Kada krenuti u bolnicu? ',
    body: `Kada se trudovi zaredaju svakih 5 minuta i češće, kada imaju ritam i trajanje barem 30 sekundi, kada su pravilni, kada tijekom kretanja ne prestaju. Kada pukne vodenjak, kada osjećate jaku i intenzivnu bol koja ne prestaje. Kada želite i imate osjećaj da morate krenuti prema bolnici.`,
    column: 2,
  },
  {
    title: 'Kako si pomoći kad osjetim bolove u međici? ',
    body: `Možete raditi Kegelove vježbe koje će ojačati mišiće perinealne regije i tako umanjiti bolove. Ukoliko je bol jaka i povezana s trudovima koji se pojavljuju u određenom razmaku te ne jenjava već postaje sve jača, obratite se svom liječniku jer postoji mogućnost da je porod započeo. Sretno!`,
    column: 1,
  },
]

export const Rađaonica = [
  {
    title: 'Kako izgleda rađaonica? ',
    body: `Iako na prvu može zvučati zastrašujuće, ona to zaista nije. Kako bi izbjegle nelagodu koja može doći s neizvjesnošću novih prostora, ljudi i situacija, svojim trudnicama savjetujem da odu na trudnički tečaj u bolnici u kojoj planiraju roditi. Tamo možete upoznati liječnike koje ćete vrlo vjerojatno sresti u rađaonici, a možda i dobijete priliku upoznati se s prostorom rađaonice i predrađaonice. U svakom slučaju, dođite pred vrata rodilišta s pozitivnim mislima. Zaprimit će Vas primalja, zatražiti Vašu dokumetaciju (kojoj svakako pridodajte i plan poroda) te Vas uvesti u prijemnu ambulantu gdje će uzeti Vaše podatke. Liječnik će Vas pregledati i prema potrebi Vas staviti na CTG uređaj. Nakon zaprimanja otvorit će Vam venski put, dati klistir ako ga želite i obaviti sve pripreme za odlazak u predrađaonicu. Tamo ćete imati vremena pripremiti se za odlazak u rađaonicu. Po dolasku u rađaonski odjeljak bit ćete smješteni na krevetu prilagođenom za rađanje koji je povišen, praktičan, s držačima za noge, ručkama, te ima razne opcije podizanja i spuštanja. Ondje će biti i monitor koji služi za mjerenje otkucaja bebinog srca i Vaših kontrakcija. Oko Vašeg trbuha primalja će postaviti gume/remenje koji će pridržavati sonde za mjerenje. Ukoliko su otkucaji bebina srca normalni i ne ukazuju na probleme možete ih, uz asistenciju, povremeno skinuti i promijeniti položaj ili prošetati. Nešto iznad lakta primalja će Vam postaviti uređaj za mjerenje krvnog tlaka koji se povremeno stišće i opušta, ali nakon nekog vremena nećete to više ni primjećivati. Do sebe u odjeljku ili u blizini primijetit ćete vagu, mjerač bebine dužine, kadicu za higijenu i ostale instrumente koji stoje tu u slučaju potrebe. Ovisno od bolnice možete zatražiti loptu, uže, kadu, stolčić za rađanje i drugo.`,
    column: 1,
  },
  {
    title: 'Kako izgleda faza tranzicije? ',
    body: `Najzahtjevnija faza koja traje kratko no bolovi su onda najjači. Vrat maternice otvara se od 7 – 10 cm, a trudovi idu jedan za drugim ne ostavljajući Vam puno međuprostora. Osjećat ćete pritisak na donji dio leđa i debelo crijevo jer se bebina glavica gura. Navala hladnih i toplih valova je normalna. Noge bi Vam se mogle grčiti i tresti, no to su normalni simptomi i to neka Vas to ne zabrinjava. Pokušajte prodisati svaki trud i opustiti se što više možete. Vjerujte liječniku kod primjene lijekova za smanjenje ili neutralizaciju boli. Ova faza može brzo napredovati, a Vi brzo biti spremni za tiskanje. Kad dođe vrijeme za tiskanje ostanite usredotočeni  i sve bi trebalo brzo završiti. Pustite maternicu da istisne vaše dijete, ne borite se protiv boli, pustite je da teče. Prosječno trajanje tranzicije je od 15 minuta do 3 sata. Ukoliko osjetite snažan poriv za tiskanjem, a vrijeme za tiskanje još nije stiglo, olakšajte si dahtanjem i puhanjem dok Vam primalja ne kaže da je došlo vrijeme.`,
    column: 2,
  },
  {
    title: 'Kako tiskati? ',
    body: `Kad trud dođe udahnut ćete zrak i, bez izdisanja, snažno tiskati prema dolje. Kad osjetite potrebu za novim zrakom, ponovno ćete snažno udahnuti i, bez izdisanja, snažno tiskati prema dolje. Kada nestane trudova svi odmaramo. Moje trudnice i ja uvijek dišemo zajedno. Vaša će beba tijekom porođaja nekoliko puta kroz prolazak kroz zdjelicu promijeniti položaj. Kroz izmjenu položaja ona se spušta nisko u rodnicu. Primalja, liječnički tim, Vaš partner i doula prije Vas će vidjeti vrh bebine glavice kada ona izroni iz rodnice. Svojim trudnicama uvijek savjetujem da pogledaju dolje jer, kada vide da je beba tu, dobiju posebnu snagu. Prvo izlazi bebina glava, jedno pa drugo rame i naposljetku ostatak tijela. Ovo je rođenje djeteta u idealnom položaju.`,
    column: 2,
  },
  {
    title: 'U kojim se položajima rađa u našim rodilištima?',
    body: `U mnoštvu knjiga koje sam pročitala i seminara koje sam pohađala čula sam za razne položaje za rađanje, od polusjedećeg, sjedećeg, stojećeg, klečećeg, ležanja na boku, čučećeg, postavljanja na koljena i ruke i ostalih. Ono što sam doživjela u Hrvatskim rodilištima je polusjedeći položaj i ležanje na boku, što ne znači da drugi položaji nisu mogući. Iskušavamo svakojake položaje, no onaj u koji se najčešće vratimo je poluležeći. Kad se majke postave poluležeće, tijekom truda sagnu se prema naprijed, gurajući bradu prema prsima i povlačeći snažno prema sebi noge, držeći se ispod koljena ili za montirane ručke na krevetu za rađanje. U tom slučaju doula im pridržava leđa, diše zajedno s njima i između trudova briše ih mokrom gazom po licu i usnama da ih osvježi i da im bude lakše. U položaju na boku Vaša doula pridržavat će Vam gornju nogu, a ovaj položaj će Vam pomoći da rasteretite leđa.`,
    column: 1,
  },
  {
    title: 'Možemo li u bolnicu povesti koga želimo?',
    body: `U većini zagrebačkih rodilišta dozvoljena osoba u pratnji je suprug, prijateljica ili doula. Pratnja kroz svaku fazu poroda može napraviti jako puno. Kako kod kuće, tako i u bolnici, mogu pratiti trudove, smirivati Vas, razgovarati s Vama, dohvatiti Vam što trebate, brinuti o Vama i ohrabrivati Vas. Kod aktivne faze ipak je drugačije jer razne mame različito reagiraju na trudove.`,
    column: 2,
  },
]

export const Porod = [
  {
    title: 'Hoće li porođaj dugo trajati?',
    body: `Trajanje porođaja ovisi od rodilje do rodilje i nikada nije jednako. Postoje tri stadija: rani porođaj, aktivni porođaj i tranzicija. Rani porođaj je faza u kojoj se vrat maternice širi od 0 – 3 centimetra. Tijekom vremena vrat maternice će se mijenjati i otvarati kako bi se beba mogla spustiti u rodnicu. Kontrakcije uzrokuju otvaranje vrata maternice i pritisak niz nju. Iako je to najdulji stadij, kontrakcije su još blage i tek počinju jačati. Kontrakcije u ovoj fazi u pravilu traju između 30 i 60 sekundi u razmacima od 5 – 20 minuta, te mogu biti pravilne ili nepravilne. Neke žene prvu fazu prespavaju jer su kontrakcije slabe i nepravilne, neke se žene u potpunosti otvore u samo nekoliko sati. Ovu fazu obično prate lagani bolovi u leđima, nelagoda u želucu i češći odlazak na toalet. Moj savjet trudnicama je uvijek isti - nastavite sa svojim uobičajenim aktivnostima koliko možete. Ako ste umorni odmarajte, gledajte televiziju, a partner ili Vaša doula neka vas masiraju, neka Vam ugode. Ako Vam odgovara tuširanje opustite se uz blagotvornu moć vode. Ukoliko Vam paše šetnja šećite, hodanje je aktivnost koja Vam može pomoći u porodu.`,
    column: 1,
  },
  {
    title: 'Mogu li imati potpuno prirodan porođaj?',
    body: `Mame koje žele imati potpuno prirodan porođaj bez uzimanja lijekova i uz uporabu prirodnih načina ublažavanja boli, to si danas mogu priuštiti. Dovoljno je u plan poroda upisati da žele prirodan porod i na prijemu liječnicima to napomenuti. Prirodne metode mogu djelovati na razne načine i uglavnom se baziraju na odvraćanju pažnje od osjećaja boli. One mogu majci pomoći da prebrodi bol, no neće je u potpunosti ukloniti. Svojim trudnicama uvijek savjetujem da pokušamo prirodno, no ako se u kasnijim fazama ne osjećamo baš najbolje, uvijek si možemo pomoći nekim od lijekova. Ako ipak nastavimo s prirodnim porodom, tada se fokusiramo na kretanje, opuštanje i disanje. Opuštanje možete uvježbati prije samog poroda: udobno se smjestite, pustite laganu muziku ili meditaciju, dišite i pokušajte opustiti cijelo tijelo. Sve to možemo ponoviti i na porodu i tako si olakšati da se u što kraćem roku opustimo, otvorimo, ne odupiremo se boli već prodišemo svaki trud i tako sami sebi olakšamo porod. Kako bi se opustili, od velike pomoći može biti i dodir ili pritisak na određene dijelove Vašeg tijela. Vaša pratnja može Vas masirati kako bi Vas dodatno opustila. Ukoliko Vam pak takvo što ne odgovara, pokušajte se opustiti uz lagani razgovor. U kretanje, opuštanje i disanje svakako uključite i svoju pratnju koja će Vam u trenutcima izgona (kada će se biti teško potpuno koncentrirati na opuštanje) biti od velike pomoći. Ukoliko uz sebe imate svoju doulu ona će sigurno  znati kako Vam olakšati i što raditi u svakom trenutku.`,
    column: 2,
  },
  {
    title: 'Kako si olakšati bolove u ranoj fazi? ',
    body: `Dišite polako. Mijenjajte položaje dok ne pronađete onaj koji Vam najviše odgovara. Pijuckajte vodu, ako smijete i želite pojedite nešto lagano. Koristite kupaonicu koliko Vam odgovara, istuširajte se usmjeravajući mlaz vode prema donjem dijelu leđa. Vaša doula neka Vam masira donji dio leđa, a možda će Vam odgovarati i topli ili hladni oblozi kako bi Vam neutralizirali bol. Usnice mažite balzamom, a za masažu će Vam dobro doći i ulje pa ga imajte na dohvat ruke. Većini trudnica pomaže pilates lopta koja je idealan alat za pokret. Gibajte se na lopti sjedeći na njoj ili oslanjajući se na nju u položajima koji Vam odgovaraju. Do prelaska u aktivnu fazu poroda nabrojane metode mogle bi vam uvelike pomoći.`,
    column: 1,
  },
  {
    title: 'Carski rez ',
    body: `Mnogo je raznih čimbenika koji mogu dovesti do carskog reza, a najčešći je razlog da porođaj ne napreduje kako treba. Može napredovati sporo ili se potpuno zaustaviti. Ponekad trudovi nisu dovoljno jaki da otvore vrat maternice ili je glavica bebe prevelika za prolazak kroz majčinu zdjelicu. Ukoliko mama očekuje blizance, šanse za carski rez su oko 50%. Nije rijetkost da se jedna od beba nalazi u neodgovarajućem položaju. Tada je carski rez sigurniji od prirodnog porođaja, osobito za dijete koje se rađa drugo. Kod višeplodnih trudnoća mudro je razgovarati s liječnikom, biti spremna na sve mogućnosti i odlučiti što je za Vas najbolje. Četiri tjedna nakon izlaska iz bolnice vjerojatno ćete se osjećati dovoljno dobro za preuzimanje uobičajenih aktivnosti.`,
    column: 2,
  },
  {
    title: 'Što se događa u fazi aktivnog porođaja? ',
    body: `U velikom broju slučajeva aktivnu fazu provest ćemo u rodilištu. To je razdoblje u kojem se vrat maternice otvara od 3 – 7 centimetara, kontrakcije postaju sve jače i sve bolnije, a mogu trajati od 45 sekundi naviše. Sve je manje vremena za odmor između trudova, ali kako se vrat maternice otvara tako se dijete sve više spušta i Vaš porod sve više i bolje napreduje. Dolaskom u rodilište presvući ćete se u svoju spavaćicu, napravit će Vam početnu obradu, a liječnici će pregledom odrediti Vaše stanje i otvorenost. Priključit će Vas na fetalni monitor (CTG) kako bi pratili otkucaje bebinog srca i Vaše kontrakcije. Provjerit će Vaše vitalne znakove te, zbog sigurnosti, otvoriti intravenski put. Aktivna faza obično traje između 3 i 8 sati. Liječnik će Vas pregledati svaka dva sata kako bi provjerio kako reagirate na trudove i napreduje li porod. Ako se osjećate umorno i nemirno, pokušajte se što više opustiti i dišite. Partner ili vaša doula ohrabrit će Vas u prolasku kroz trudove i u satima koji slijede. Podsjećat će vas da popijete vode, dišete te maksimalno opuštate sebe i svoje tijelo.`,
    column: 1,
  },
  {
    title: 'Kada prednost završetka poroda dajemo carskom rezu? ',
    body: `Kad vodenjak pukne postoji mogućnost da pupkovina isklizne iz maternice prije bebina rođenja, a to predstavlja veliku opasnost za bebu koja pritiskom glavice na pupkovinu može zaustaviti dotok kisika. Taj problem vrlo je rijedak kada je beba u položaju glavicom prema dolje. No, ako je pupkovina omotana oko vrata Vaše bebe ili je zaglavljena između bebine glave i zdjeličnih kostiju, ili ako je smanjena količina tekućine, svaka kontrakcija stisnuti će pupkovinu i usporiti dotok kisika. Nažalost, najčešće nije moguće sa sigurnošću znati gdje se nalazi pupčana vrpca sve do rođenja bebe. U tim slučajevima carski rez najbolja je mogućnost završetka poroda.`,
    column: 2,
  },
  {
    title: 'Što sve može djelovati na tijek porođaja? ',
    body: `Oblik i širina majčine zdjelice – prilikom bebina spuštanja i poroda beba mora imati dovoljno mjesta kako bi prošla kroz tri zdjelične kosti koje čine šupljinu kroz koju prolazi dijete. Priroda nam pomaže na način da bebe najčešće odgovaraju zdjelici svoje majke, tako da mame koje imaju uže zdjelice obično imaju sitnije bebe. Također, blizu termina poroda, tijelo otpušta hormon relaksin kako bi se ligamenti zdjelice opustili, šireći je i opuštajući je za porod. To omogućuje da se u većini slučajeva dobije dovoljno mjesta za bebin prolazak u vanjski svijet. Većina žena nema problema s otvaranjem vrata maternice, no kod nekih otvaranje traje duže i mukotrpnije je. Tiskanje je važno kako bi bili što fokusiraniji i u što kraćem roku istisnuli svoje dijete van. Kako kosti bebine lubanje još nisu srasle, glavica se prilagođava obliku i veličini zdjelice. Ponekad, kada je pod lošim kutem, glavica može utjecati na vrijeme i intenzitet nelagode na porodu. Glede položaja, porođaj je najjednostavniji kad je novorođenče okrenuto glavicom prema dolje. Može se dogoditi da se beba nalazi u položaju zatkom, nožicama ili trticom. Beba može biti položena poprijeko ili ramena biti vodeća čest. Važno je da ste informirani, a za sve ostalo pobrinut će se liječnički tim i primalja koja će Vam biti na raspolaganju.`,
    column: 1,
  },
  {
    title: 'Koji su rizici carskog reza?',
    body: `Carski rez je operacija, velika operacija koja se izvodi kako bi se riješile po život opasne komplikacije. U odnosu na prirodan vaginalni porod povećani su određeni rizici poput gubitka krvi koji je tada, u prosjeku, dvostruko veći. Iako rijetke, moguće su ozljede mokraćnog mjehura i crijeva. Najčešća je komplikacija upala i infekcija unutarnje stijenke maternice. Također može doći do reakcije na anesteziju i lijekove, infekcije mokraćnog sustava, sporijeg rada crijeva,  stvaranja ugrušaka u venama, infekcije rane, rastvaranja rane duž linije reza, prirasle posteljice i histerektomija. Rizici za Vašu bebu su prijevremeni porođaj, poteškoće disanja i, iznimno rijetko, ozljede bebe.`,
    column: 2,
  },
  {
    title: 'Što je dobro, a što nije nakon carskog reza? ',
    body: `Ograničite Vaše aktivnosti na brigu za sebe i Vašu bebu. Ne opterećujte ranu i izbjegavajte napore i dizanje tereta većih od težine Vaše bebe. Pružite sebi priliku da se odmorite i oporavite. Nemojte voziti ni vježbati sve dok Vam to liječnik ne dopusti. Naravno, sa svime krenite polako i pažljivo. Plivanje i hodanje u početku su dobar izbor aktivnosti.`,
    column: 2,
  },
  {
    title: 'Što je CTG i što on prati? ',
    body: `CTG uređaj prati otkucaje srca Vaše bebe. Bebino srce može kucati normalno, no neke pojave mogu ukazivati na probleme s opskrbom kisikom, pritiskom na pupčanu vrpcu ili nedovoljnom funkcijom posteljice. Ukoliko je situacija zabrinjavajuća liječnik bi mogao savjetovati carski rez. Nažalost, u takvim je situacijama teško precizno procijeniti stvarni rizik. Odluka o tome je li i kada potreban carski rez ovisi o mnogim čimbenicima, uključujući informaciju o prenešenosti bebe i procjenu o trajanju porođaja.`,
    column: 1,
  },
  {
    title: 'Što ako je beba u nepravilnom položaju? ',
    body: `Kod beba koje se nalaze u nepravilnom položaju i ulaze u porođajni kanal zatkom, velika je vjerojatnost da će se roditi carskim rezom zbog velike mogućnosti komplikacija. Vješti liječnik prije početka porođaja može pokušati „vanjskim okretom“ okrenuti bebu u pogodniji položaj. Kod stava zatkom znatno se češće događa prolaps pupčane vrpce koji može rezultirati zaustavljanjem dotoka kisika prema bebi. Također, glavica bebe može ostati u porođajnom kanalu i nakon prolaska tijela. Ako beba leži u poprečnom položaju to zahtjeva carski rez.`,
    column: 2,
  },
  {
    title: 'Što je epiduralna blokada?',
    body: `Epiduralna blokada je mješavina anestetika i narkotika. Koristi se za vrijeme aktivnog dijela poroda, a može se koristiti i za vrijeme carskog reza. Lijek se uvodi u prostor koji okružuje živce kralježnične moždine iglom kroz koju se uvodi savitljiva cjevčica. Iglu se uklanja, a kroz cjevčicu se inicira lijek kako bi dopro do živca i blokirao osjećaj boli. Postupak ne boli jer liječnik prije zahvata određeno područje na leđima umrtvi lokalnim anestetikom. Epiduralna blokada privremeno blokira bol u donjem dijelu leđa ili utječe na Vaš doživljaj boli. Ona Vam pomaže da tijekom porođaja ostanete budni i svjesni. Pred kraj poroda doze će se umanjiti ili se više neće dati da bi se osigurao bolji osjećaj tiskanja. Epiduralna blokada u vrlo rijetkim slučajevima nosi nuspojave, poput djelovanja anestezije na samo jednoj strani tijela ili snižavanja maminog krvnog tlaka koje može utjecati na protok krvi kroz posteljicu i privremeno usporiti rad bebina srca. Mama se može osjećati omamljeno i loše ili imati otežano disanje, asmože se i pojaviti alergija na lijekove.`,
    column: 1,
  },
  {
    title: 'Što ako mi dijete bude preveliko kada dođe vrijeme za porod? ',
    body: `To se događa i neke bebe zaista jesu prevelike da bi se rodile prirodnim putem. Veličina bebe, a ujedno i bebine glavice, mogla bi biti posebno važna ukoliko imate malu zdjelicu. To se vrlo rijetko događa, osim ako niste pretrpjeli neku traumu ili nemate neku deformaciju. Ako ste kroz trudnoću oboljeli od šećerne bolesti pa je Vaša beba dobila mnogo na težini, to može biti problem. Ukoliko je dijete dosegnulo 4,5 kilograma prije rođenja, postoji veća vjerojatnost za carski rez, iako planiranje carskog reza zbog težine nije opravdano kako težinu nije moguće točno procijeniti.`,
    column: 2,
  },
  {
    title: 'Što ako mama ima ozbiljan zdravstveni problem?',
    body: `Carski rez mogao bi biti potreban ukoliko imate zdravstveni problem poput povišenog krvnog tlaka uzrokovanog trudnoćom, bolesti srca, pluća, šećerne bolesti… Takva stanja često dovode do odluke da se mamu stavi na inducirani porođaj. U slučaju indukcije mogu se registrirati nepravilni srčani otkucaji, a vrat maternice može se u velikom broju slučajeva sporo otvarati, što često povećava mogućnost carskog reza. Prirodni porod u mnogim je slučajevima preporučljiv za majčino zdravlje, npr. kod majki koje imaju bolest srčanih i/ili plućnih arterija te kod žena s povišenim krvnim tlakom u trudnoći. Ako majka ima herpes u genitalnom traktu, liječnik bi se mogao odlučiti na sekciju da zaštiti bebu od bolesti. Ukoliko imate ozbiljan zdravstveni problem tijekom trudnoće, razgovarajte s liječnikom i pripremite se na sve mogućnosti.`,
    column: 2,
  },
  {
    title: 'Što je spinalna anestezija? ',
    body: `Kao i epiduralna anestezija, spinalna anestezija je mješavina anestetika i narkotika. Ona se koristi u aktivnom dijelu porođaja ili neposredno pred carski rez. Njezinim uvođenjem djelovanje počinje odmah. Ova tehnika potpuno uklanja bol od prsa prema dolje i ublažava bolove od trudova, vaginalnog poroda ili carskog reza. Mama je svjesna svega tijekom porođaja. Nuspojave ovog zahvata mogu biti glavobolja i sniženi krvni tlak kod majke, koji može dovesti do teškoća i kod bebe.`,
    column: 1,
  },
  {
    title: 'Samo pozitivno!',
    body: `Sve moje trudnice znaju da je pozitivan stav jedna od najvažnijih stvari koja istinski i stvarno utječe na tijek porođaja. Vjerujte liječničkom timu, on je tu za Vas i zbog Vas. Kroz trudnoću se informirajte o tome što Vas čeka, koje su mogućnosti, kako si pomoći da porođaj prođe lako i bez većih problema. Strašljive i napete žene često lošije prođu na porodu zbog svoje nesigurnosti, straha i stresa koji mogu dovesti do niza fizioloških reakcija u tijelu, koje onda mogu utjecati na tijek poroda. Kako se to ne bi dogodilo, važno je biti pozitivan i imati pozitivan stav.`,
    column: 1,
  },
  {
    title: 'Što kada majka nosi blizance ili drugu višeplodnu trudnoću?',
    body: `U tom slučaju vrlo je važno da vas liječnik kroz trudnoću uputi na sve mogućnosti kako bi bili spremni kada porod krene. Svaka višeplodna trudnoća je jedinstvena. Čak i kada se bebe pred kraj trudnoće nalaze u stavu glavicom, to ne mora tako i ostati do samog rođenja. Oko polovice svih žena koje rađaju blizance rode carskim rezom, sve ovisi o gestacijskoj dobi, položaju i procijenjenoj masi. Porođaj carskim rezom je sigurniji ako se jedna od beba nalazi u neodgovarajućem položaju, posebno za dijete koje se rađa drugo. `,
    column: 2,
  },
  {
    title: 'Podrška i potpora vrlo su važne!',
    body: `Liječnički tim, primalje, Vaš partner i Vaša doula važna su podrška koja je svakoj rodilji potrebna i koja uvelike pomaže na pozitivno iskustvo rađanja koje svi priželjkujemo. Svi oni biti će s Vama u rađaonici i zajedno djelovati kako bi Vam pružili podršku i pomogli da se opustite tijekom porođaja. Svojom prisutnošću, savjetima i aktivnim sudjelovanjem pomoći će da se suočite sa svim izazovima koji su ispred Vas. Medicinski tim držat će Vas pod nadzorom, brinuti će za Vas i usmjeravati Vas ovisno o fazi u kojoj se nalazite. Vaša primalja stalno će biti tu na usluzi, kada god je zatrebate.`,
    column: 1,
  },
  {
    title: 'Što kada dođe do komplikacija s posteljicom?',
    body: `Dva su problema povezana s posteljicom: predležeća posteljica i odvajanje posteljice od unutarnje stijenke maternice prije početka porođaja. Takvo stanje može uzrokovati po život opasne probleme za mamu i bebu. Ukoliko do toga dođe biti će potreban hitni porođaj. Ako pak posteljica leži nisko u maternici i prekriva otvor vrata maternice, najčešće će biti potreban carski rez. Kad bi posteljica izašla prva beba bi ostala bez izvora kisika, a majka bi bila izložena jakom krvarenju. Stoga je carski rez u ovakvim slučajevima najsigurnije rješenje za mamu i bebu.`,
    column: 2,
  },
  {
    title: 'Kako pomoći rodilji tijekom trudova? ',
    body: `Tijekom trudova stavljajte joj hladne ili tople obloge, vlažite joj lice i usnice, sugerirajte joj promjene položaja, a ako joj odgovara masirajte joj tijelo. Nekim ženama odgovara njihov vlastiti mir, neke slušaju meditacije, neke su mislima na nekom drugom mjestu i ne trebaju nikoga. U tom slučaju i sama Vaša prisutnost rodilji je dovoljna. Na samom izgonu majci treba ohrabrenje, dišite s njom, držite je za ruku, tiskajte zajedno s njom, osluškujte je i znati ćete što joj u tom trenutku treba. Ukoliko ste joj suprug recite joj da je volite i da radi sve kako treba - sada joj treba Vaša puna podrška.`,
    column: 1,
  },
  {
    title: 'Do kakvih bi komplikacija moglo doći po izlasku iz bolnice…',
    body: `Temperatura, bolno i neugodno mokrenje, iscjedak, crvenilo i vlaženje na mjestu reza, jaka bol u trbuhu ili razdvajanje rubova reza su simptomi koji Vam signaliziraju da se morate hitno javiti liječniku.`,
    column: 2,
  },
]

export const Novorođenče = [
  {
    title: 'Po rođenju bebice… ',
    body: `Ona je još povezana s majkom pupčanom vrpcom. Kako nema razloga za žurbu, u posljednje vrijeme sve više majki želi da se pupkovina pusti da otpulsira još neko vrijeme nakon poroda. Vaš partner ili Vaša doula prerezati će pupkovinu (što, moram priznati, meni osobno nije baš ugodan osjećaj), a novorođenče će biti položeno na majčin trbuh ili prsa. To je trenutak velike sreće koja preplavi sve prisutne u porođajnom odjeljku, a posebno roditelje. Dijete će pregledati pedijatar, dati mu ocjenu po Apgarovoj ljestvici, izmjeriti i izvagati dijete, zamotati ga i vratiti Vam ga u naručje. Na ruku ćete dobiti narukvicu s istim brojem kojeg ima i Vaše dijete kako ne bi došlo do pogreške u rodilištu. Ukoliko sve bude u najboljem redu, bebicu ćete moći držati i dojiti u satima iza porođaja.`,
    column: 1,
  },
  {
    title: 'Što je rodin ugriz? ',
    body: `Rodin ugriz pokriva veće ili manje područje kože. Koža je na tom mjestu crvena, ali nema razloga za zabrinutost. Nestaje spontano tijekom nekoliko godina.`,
    column: 2,
  },
  {
    title: 'Kako izgledaju toliko spominjane pjege? ',
    body: `Boje su bijele kave, nepravilnog oblika i veličine. Novorođenče može imati jedno ili više područja pojačane pigmentacije na koži, a najčešće se radi o bezazlenoj pojavi.`,
    column: 2,
  },
  {
    title: 'Što kada beba ima hemangiom? ',
    body: `Hemangiom je veća ili manja crvena tvorba iznad razine kože. Građen je od spleta krvnih žilica. Pozornost izaziva svojim smještajem i žarkom bojom, no nema razloga za zabrinutost. Hemangiom će nestati kako se krvne žile spontano zatvaraju.`,
    column: 2,
  },
  {
    title: 'Kako izlazi posteljica? ',
    body: `Nakon rođenja djeteta očekuje nas još i izlazak posteljice. Dok s partnerom dijelite zajedničke trenutke s djetešcem, Vi ćete i dalje imati lagane kontrakcije kako bi organ koji se nalazi unutar Vaše maternice, a hranio je Vašu bebu tijekom trudnoće, mogao izaći. Posteljica će se u većini slučajeva odvojiti od stijenke maternice u roku od 5 do 10 minuta nakon porođaja, a ako se to ipak ne dogodi, liječnik će Vas poticati na tiskanje dok će on lagano povlačiti pupkovinu za njen vanjski dio. Primalja će Vam masirati donji dio trbuha kako bi potaknula trudove i olakšala izlazak posteljice. Pomoći si možete stavljanjem bebe na prsa i dojenjem jer će na taj način Vaše tijelo početi otpuštati hormon oksitocin koji potiče trudove. Nakon izlaska posteljice majkama se često daje lijek za poticanje kontrakcija maternice. Kontrakcije su vrlo važne jer djeluju na zatvaranje krvnih žila i smanjuju krvarenje. Na taj način maternica će se lakše vratiti na svoju staru veličinu. Ukoliko se posteljica ne odvoji spontano, liječnik će ju rukom ukloniti iz maternice. Bolničko osoblje dobro će pregledati posteljicu nakon što se porodi kako ne bi bilo zaostalih dijelova u maternici koji mogu izazvati krvarenje i infekciju. U posljednje vrijeme imala sam nekoliko upita majki koje su htjele uzeti posteljicu kako bi je posadile u vrtu ili spremile, no bolničko osoblje uvijek je dalo negativan odgovor.`,
    column: 1,
  },
  {
    title: 'Beba ima sukrvicu iz pupka, što sad?  ',
    body: `Novorođenčad je sklona krvarenju zbog prolazne nezrelosti mehanizma za zgrušavanje krvi, stoga joj se daje injekcija vitamina K. Manjak K vitamina nastaje zbog postupnog preuzimanja funkcije jetre i crijeva. Pojava sukrvice u pupku povezuje se s manjkom vitamina K.`,
    column: 2,
  },
  {
    title: 'Dijete ima vodenu kilu, što sad? ',
    body: `Vodena kila naziv je za pojavu otečenih mošnji tijekom prvih tjedana života. Najčešće prolazi spontano.`,
    column: 2,
  },
  {
    title: 'Jesu li nabubrene dojke u novorođenčeta normalna pojava? ',
    body: `Tijekom prvih tjedana nakon rođenja kod novorođenčeta možete primijetiti natečenost dojki. Razlog njima su majčini hormoni koji se luče putem majčina mlijeka. Kod djevojčica se djelovanje hormona može vidjeti i pojavom guste, sukrvave sluzi iz vagine. Intervencija liječnika nije potrebna, a pojave nestaju same od sebe.`,
    column: 2,
  },
  {
    title: 'Djetetu je pukla ključna kost tijekom poroda, je li to uobičajeno?',
    body: `Pri težem porodu može doći do prijeloma ključne kosti. Iako spontano zaraste, prijelom se najčešće prepozna naknadno jer se iznad ključne kosti stvori zadebljanje, odnosno otvrdnuće kosti. Nikakve posebne mjere nisu potrebne.`,
    column: 2,
  },
  {
    title:
      'Kažu da će se moja beba roditi prije vremena, kako će to izgledati?',
    body: `Vaš prvi susret s prijevremeno rođenom bebom biti će na neonatalnom odjelu. Na prvi pogled možda ćete biti ganuti i malo šokirani jer će Vaša beba biti sićušna, no ubrzo ćete se prilagoditi uvjetima i velikom broju pomagala koja su tu za Vašu bebu. Sve mjere koje su tu služe kako bi medicinsko osoblje moglo pratiti stanje djeteta i kako bi ono ostalo zdravo. U održavanju normalne tjelesne temperature djetetu će pomoći inkubator, odnosno grijani krevetić u kojem će ležati. Koža prijevremeno rođene bebe mogla bi izgledati prozirno i biti prošarana krvnim žilicama te pokrivena nježnim lanugo dlačicama, dok bi tijelo zbog manjka masnih zaliha moglo biti oštro, manje okruglo, tanko, osjetljivo i prozirno.`,
    column: 1,
  },
  {
    title: 'Je li žutica u novorođenčeta česta pojava?',
    body: `Žutica se može podijeliti na fiziološku i patološku. Potrebno ih je razlikovati zbog različitog dijagnostičkog i terapijskog pristupa. Osim vrijednosti bilirubina analizira se i rast koncentracije bilirubina u satima nakon porođaja, gestacijska dob, porođajna masa i prisutnost drugih patoloških stanja u novorođenčeta. Prema izmjerenim vrijednostima bilirubina pedijatar neonatolog će odrediti terapiju. Fiziološka žutica je prolazna, očituje se pojavom žute obojenosti kože i očiju, a najčešće nestaje od tri do pet dana nakon poroda. `,
    column: 2,
  },
  {
    title: 'Cijepljenje - da ili ne? ',
    body: `Cijepljenje je postupak kojim se nastoji spriječiti mogućnost obolijevanja od bolesti za koju postoji odgovarajuće cjepivo. Cijepljenje je jedno od velikih dostignuća suvremene medicine. Novorođenče se već tijekom boravka u rodilištu počinje cijepiti. Obvezna cjepiva su ona protiv tuberkuloze i hepatitisa B. Neželjene reakcije ne postoje. Jedino se, tijekom prvog do drugog mjeseca, na lijevoj nadlaktici može vidjeti ožiljak od cjepiva protiv tuberkuloze.`,
    column: 1,
  },
  {
    title: 'Dojenje kod produljene fiziološke žutice – da ili ne?',
    body: `Osim promjene boje kože i očiju moguća je i pojava pospanosti, slabijeg uzimanja hrane i slabijeg pražnjenja crijeva tijekom trajanja žutice. Mogućnost štetnog djelovanja povišenih bilirubina na pojedine strukture mozga iznimno je mala. Upravo je ta spoznaja, uz sve već poznate dobrobiti dojenja, utjecala na to da se dojenje ne prekida tijekom produljene fiziološke žutice jer ono ima znatnu zaštitnu ulogu. Svom djetetu roditelji mogu pomoći tako da nastave isključivo dojiti, učestalo, više od osam puta dnevno. Djetetu nisu potrebne dodatne tekućine. Dijete je poželjno kratkotrajno izlagati sunčevoj svjetlosti (iza stakla), te pratiti ponašanje novorođenčeta, redovitost i izgled urina i stolice.`,
    column: 2,
  },
  {
    title: 'Kada će nas otpustiti iz rodilišta? ',
    body: `Dijete i majka otpuštaju se iz rodilišta treći dan nakon rođenja. Ukoliko je novorođenče imalo zdravstvenih problema zadržava se na odjelu za neonatologiju. U otpusnom pismu biti će vidljivi rezultati obavljenih pretraga, način liječenja i dane smjernice za daljnju liječničku kontrolu. Majka će dobiti upute i savjete o daljnjoj brizi, njezi i prehrani kod kuće. `,
    column: 1,
  },
  {
    title: 'Hoćemo li se snaći nakon izlaska iz rodilišta? ',
    body: `Svakako se treba javiti patronažnoj sestri zaduženoj za Vaše područje, ukoliko to još nije učinila bolnica. Patronažna sestra biti će Vam dragocjena u prvim danima njege djeteta. Provjerite je li dijete prijavljeno u područni matični ured, odnosno u Hrvatski zavod za zdravstveno osiguranje. Ukoliko nije, treba ga prijaviti. Odaberite liječnika primarne zdravstvene zaštite i dogovorite prvi pregled sa mjesec dana.`,
    column: 1,
  },
]

export const Majčinstvo = [
  {
    title: 'Kako se njegovati nakon poroda? ',
    body: `Dajte si vremena za oporavak jer je kroz devet mjeseci Vaše tijelo proživjelo razne traumatične promjene. Razdoblje nakon poroda je iscrpljujuće i mogli biste osjećati različite nelagode i bolove. S vremenom ćete se sve bolje osjećati i vratiti se u normalnu formu. Njega dojki vrlo je važna jer one hrane naše dijete. Dojke će vam biti povećane, stoga nosite grudnjak koji Vam odgovara veličinom i čvrstoćom. Svakodnevno perite svoje dojke i bradavice vodom i njegujte ih za to predviđenim sredstvima. Ne koristite sapun kako se koža ne bi isušila i pucala jer to može pogoršati osjetljivost i stvoriti dodatne probleme.`,
    column: 1,
  },
  {
    title: 'Kada početi s kontracepcijom?',
    body: `Bilo bi dobro da počnete razmišljati o kontracepciji odmah po rođenju djeteta. Postoje slučajevi u kojima su majke i prije prve menstruacije zatrudnjele. Osim što trudnoća za vrijeme dok imate malu bebu nije lagana, ona nije ni dobra za Vaše zdravlje. Upitajte svog ginekologa koja bi metoda za Vas, s obzirom na Vaše zdravlje i trenutnu situaciju, bila najpovoljnija.`,
    column: 2,
  },
  {
    title: 'Kada se vratiti na posao?',
    body: `Pitanje koje muči mnoge majke jer se miješaju osjećaji brige za dijete, materijalne potrebe i dugo građena karijera. Rađena su istraživanja diljem svijeta, a rezultati su različiti. Neke studije ukazuju na blaže izražen negativan utjecaj na djecu čije su majke počele raditi u ranijem djetinjstvu. Druge pak ukazuju da sudjelovanje u kvalitetnoj skupini djeci pomaže djetetu u socijalizaciji. Ono u čemu se svi slažu je da je važno kvalitetno provoditi vrijeme sa svojom djecom, a to znači biti u potpunosti prisutan fizički (aktivnom igrom), psihički i emotivno. Roditelji koji i ostanu kod kuće često su zatrpani nizom obveza i ne stižu kvalitetno provoditi vrijeme s djetetom. Ono što činjenice i mi sami možemo potvrditi je da su povezanost i ljubav između roditelja i djece najvažnije. Prvo se pobrinite za vlastitu sreću i ispunjenje, a onda će i Vaše dijete biti sretno.`,
    column: 2,
  },
  {
    title: 'Što napraviti kod prepunjenosti dojki mlijekom?  ',
    body: `U početku Vaše dojke sadrže kolostrum, a u nekoliko se dana nakon poroda počnu puniti mlijekom. Dojke će postati teže, čvršće, otečene, bolne, crvene… Ukoliko ne dojite, Vaše bi dojke mogle postati vrlo bolne, tvrde i pune kvržica, što bi moglo dovesti do problema i velike nelagode. Čak i ako dojite, Vaše bi se dojke s vremena na vrijeme mogle prenapuniti. Ukoliko do toga dođe stavite dijete na prsa, a ako niste u mogućnosti izdojite mlijeko ručno ili izdajalicom, tek toliko da nelagoda i napetost nestanu. Koristite tople ili hladne obloge, tuš ili kupku. Ukoliko ste pak odlučili ne dojiti,  izbjegavajte izdajanje i masiranje dojki da ne potaknete stvaranje novog mlijeka. U slučaju visoke temperature, malaksavosti i osjećaja nemoći svakako se obratite liječniku za pomoć. Kada sam s drugim djetetom dobila mastitis pomogli su mi, prema starom narodnom savjetu, oblozi od kupusa i peršina.`,
    column: 1,
  },
  {
    title: 'Što kad tata ostane kod kuće, a mama ode raditi? ',
    body: `Tata preuzima maminu ulogu dok je ona na poslu, a kada se vrati, mama preuzima svoju tradicionalnu ulogu majke i nastavlja se brinuti o djeci zajedno s tatom. Provode vrijeme s djecom u igri i obavljanju kućanskih poslova. Ni tata ne gubi svoju tradicionalnu ulogu u kojoj montira po kući, odčepljuje odvode i obavlja sitne popravke. Takvo ponašanje rezultira boljom povezanošću majke i oca s djecom, a djeca u kriznim i manje kriznim situacijama jednako traže i oca i majku.`,
    column: 2,
  },
  {
    title: 'Mogu li spriječiti kapanje mlijeka? ',
    body: `Moglo bi Vam se dogoditi da Vam između hranjenja, za vrijeme, prije i nakon, mlijeko nekontrolirano kapa, posebno u prvim tjednima nakon poroda. U tome će Vam pomoći jastučići za dojilje. Jastučiće mijenjajte kada se smoče mlijekom i vodite pojačanu higijenu. Nemojte se izdajati jer bi to moglo potaknuti dodatno stvaranje mlijeka i kada to nije potrebno. Mlijeko može kapati bilo kada i bilo gdje i to je sasvim normalno. `,
    column: 1,
  },
  {
    title: 'Kada je najbolje ići na drugo dijete?',
    body: `Ne postoji idealno vrijeme, no svakako treba razmotriti nekoliko činjenica prije konačne odluke. Još jedan član obitelji donosi veliku sreću i zadovoljstvo, no zahtjeva cijeli niz psihičkih, fizičkih i emotivnih napora. Kako će se na vaš posao i karijeru odraziti dug izostanak? Imamo li logistiku koja nas može podržati? Hoćemo li moći financijski podnijeti novonastalu situaciju? Imamo li dovoljno životnog prostora za novog člana ili moramo razmišljati o preseljenju? Kada Vi i Vaš partner donosite odluku, imajte na umu da ne postoji idealno vrijeme - najvažnije je da djetetu date pažnju i ljubav i tada je svako vrijeme ono pravo.`,
    column: 2,
  },
  {
    title: 'Kako njegovati bradavice?',
    body: `Dok obrađujemo temu dojenje, svojim mamama često ponavljam da su bradavice vrlo osjetljive i da o njima treba voditi posebnu pažnju. Već prvi puta kada stavite dijete na dojku osjetit ćete snagu kojom beba siše i koliko to može biti neugodno. Vaše bradavice trebat će vremena da se naviknu na pritisak, no osjetljivost i nelagoda obično nestaju nakon nekoliko dana. Bez obzira što Vi sve dobro radite i što mijenjate položaj bebe na dojkama, bradavice mogu ispucati i postati bolne i inficirane. Kako bi si pomogli, potrudite se da je beba dobro stavljena na dojku i da je što veća površina bradavice i dojke u bebinim ustima. Kada bebu odvajate od dojke činite to nježno (možete staviti mali prst u rub djetetovih usta kako bi se dojka lakše odvojila od usta). Kada ne dojite ili kada odmarate, držite dojke na suhom i otvorenom. To će biti lakše ako imate grudnjak za dojenje i malo otvorite prozorčiće. Izbjegavajte plastificirane jastučiće i sintetičku odjeću. Ako su bradavice već oštećene, možete koristiti šeširiće kako bi si olakšali nelagodu. Danas na tržištu postoje i sredstva koja će Vam pomoći u tome. `,
    column: 1,
  },
  {
    title: 'Koliki razmak između djece je najbolji?',
    body: `Iz mog iskustva razmak od 2-5 godina je najbolji. Naše tijelo se oporavilo od prethodne bebe i dojenje je završilo. Vaše prvo dijete već je stasalo, dobilo je svu potrebnu pažnju i ljubav koju sada može podijeliti s drugim djetetom. Starije dijete u početku biti će dovoljno veliko da se može i samostalno igrati kada se vi posvećujete dojenju i brizi za drugo dijete. Djeca će po godinama još uvijek biti dovoljno blizu da se mogu s vremena na vrijeme igrati zajedno. Biti će razmirica oko igračaka, ali to je potpuno normalno. Mogla bi se pojaviti ljubomora kod prvog djeteta, no i to je potpuno normalno i, najvažnije, prolazno. Kombinirat ćete svoje aktivnosti i vrijeme s jednim i s drugim djetetom sve dok ne budu dovoljno veliki za zajedničke aktivnosti. Sve to može biti prilično stresno, no dječica su veliko veselje i sreća i za njih nam ništa nije teško.`,
    column: 2,
  },
  {
    title: 'Što ukoliko dođe do začepljenosti kanalića? ',
    body: `Razlog tome može biti pritisak na dojku, začepljenje otvora na bradavici, stisnutost grudnjaka… Koža postane namreškana, nepravilna, crvena. Tada stavite dijete na bolnu dojku i pustite ga da napravi svoje uz lagano masiranje i razbijanje grudice izvana. Ukoliko dođe do teže upale, crvenila i temperature obavezno se javite svom liječniku kako bi dobili pomoć i što prije uspostavili ponovno, redovito dojenje. U rijetkim, težim slučajevima mogao bi biti potreban i manji zahvat za rješavanje problema.`,
    column: 1,
  },
  {
    title: 'Kako pripremiti dijete za dolazak novog člana obitelji? ',
    body: `Kada obitelji obznanimo da smo trudni dođe do općeg veselja, vesele se i djeca i odrasli. No kada prinova zaista i dođe, kod starije bi djece moglo doći do burne reakcije. Djeca se znaju povući u sebe, imati ispade bijesa, inata. Mogu osjećati strah da više nisu voljeni i željeni, da će biti odbačeni. Kako bi ublažili posljedice i pripremili starije dijete za događaje pred nama treba ga uključiti u sve vezano za bebu, opremanje doma, kupnju odjeće i potrepština za bebu, pokazivati djetetu kako se beba razvija u trbuhu u sličicama. Kupite Radosnicu i neki mali poklon od djeteta za bebu kada iz bolnice dođe kući. Družite se s prijateljima koji imaju više od jednog djeteta kako bi to i Vašem postane normalno i prirodno. Pripremite dobrodošlicu za novog člana obitelji i aktivno uključite dijete u sve aktivnosti. `,
    column: 2,
  },
  {
    title: 'Jesu li problemi s dojkama normalni? ',
    body: `Neke majke imaju uvučene bradavice i to im čini problem, posebno u početku. Jedan od savjeta je pokušati izdajalicom izvući bradavicu kako bi je dijete lakše uhvatilo i uspješno sisalo. Problem će se možda ublažiti kada navre mlijeko i ispupči uvučene bradavice. U svakom slučaju treba biti uporan i isprobavati koji je način za Vas najbolji. `,
    column: 1,
  },
  {
    title: 'Kako si pomoći kod navale mlijeka?',
    body: `Dojke su kod navale mlijeka velike, tvrde i osjetljive. Možete si olakšati čestim i dugim dojenjem. Ako dijete nije gladno ili je uspavano i ne budi se dovoljno često, grudi možete opustiti izdajanjem, no tek toliko da napetost nestane. Tih dana vrlo je važno nositi grudnjak za dojenje koji olakšava nošenje prenapunjenih dojki. `,
    column: 1,
  },
  {
    title:
      'Što ako je na porodu bila potrebna epiziotomija ili ako je došlo do razdora međice?',
    body: `Ovisno o veličini reza ili puknuću, neko vrijeme nakon poroda biti će Vam potreban oporavak. Kao i kod drugih kirurških rana, bit će potrebno dva do šest tjedana da se tkivu vrati uobičajena čvrstoća. Tjedni nakon poroda mogli bi biti bolni i neugodni, pogotovo tijekom kretanja i sjedenja dok rana ne zacijeli. Pokušajte se što više odmoriti i njegujte svoje tijelo. Kako bi si olakšali možete  stavljati hladne obloge, ispirati ranu pri mokrenju, nabavite zračni kolut na kojem možete lakše sjediti. Držite ranu na zraku, sjedite na stranu a ne cijelom površinom, zatražite u ljekarni sprej protiv bolova. Redovito radite Kegelove vježbe, s njima možete u krevetu početi već dan, dva nakon poroda.`,
    column: 2,
  },
  {
    title: 'Dojke su mi bolne i ispucale, kako si pomoći? ',
    body: `Majke se često susreću s bolnim i ispucalim bradavicama do kojih dolazi stavljanjem djece u nepravilan položaj pri dojenju. Tu pojavu spriječit ćete tako da krenete ispočetka i stavite dijete u pravilan položaj ukoliko primijetite da ono isprva nije dobro prihvatilo bradavicu. Grudi je važno održavati čistima i suhima. Ukoliko se stvore ragade možete ih mazati sredstvima za brže cijeljenje, vlastitim mlijekom ili ih jednostavno držati na suhom.`,
    column: 1,
  },
  {
    title: 'Kako si olakšati prve tjedne nakon poroda? ',
    body: `Tijekom prvih tjedana nakon porođaja sigurno ćete osjećati veliki umor i nedostatak energije. Nakon trudnoće ili iscrpljujućeg poroda očekuje Vas vrlo naporan ritam oko bebe i zadovoljavanja njenih potreba. Izbjegavajte podizanje tereta veće mase od Vaše bebe, spavajte uvijek kada beba spava i prihvatite svu pomoć koju Vam uža okolina pruža. Goste u potpunosti ograničite samo na uski krug. Odmarajte se, čitajte i opuštajte kada god stignete. Uključite partnera oko brige za dijete izmjenama kod hranjenja noću. Ako premorenost ne popušta, javite se za savjet liječniku.`,
    column: 2,
  },
  {
    title: 'Što kada se stvore manje ili veće kvržice?',
    body: `Ukoliko osjetite sitne ili krupnije kvržice pod rukom, postoji mogućnost da je došlo do začepljenja mliječnih kanalića. Oni mogu uzrokovati infekciju, stoga je vrlo važno stavljati dijete na grudi da se dojka isprazni. Ako to ne riješi problem možete si pomoći izdajalicom ili isprazniti dojku rukom. Ako sve prethodno ne daje rezultate pokušajte s toplom kupkom, oblozima i masažom.`,
    column: 1,
  },
  {
    title: 'Dobila sam mastitis, što da radim? ',
    body: `Do upale mliječnih žlijezda može doći infekcijom oštećene bradavice ili nedovoljnim pražnjenjem dojke. Stvari se uvelike kompliciraju, dojke su bolne, crvene i upaljene. Mamina temperatura raste, ona se osjeća nemoćno i umorno. Tada je važno konzultirati se s liječnikom. Liječnik će Vam prepisati antibiotike i savjetovati odmor i mnogo tekućine. Ukoliko imate mastitis možete nastaviti s dojenjem jer Vaša upala ne utječe na Vaše dijete.`,
    column: 1,
  },
  {
    title: 'Imam hemeroide, kako ih ublažiti? ',
    body: `Ukoliko ste tijekom trudnoće ili nakon napornog poroda primijetili bolnost ili otekline u predjelu anusa, najvjerojatnije imate hemeroide. Neke trudnice tijekom trudnoće dobiju proširene vene na kraju debelog crijeva. Unutar debelog crijeva nastaju bolni, nateknuti čvorići. Liječnik će vam preporučiti da jedete laganiju hranu, hranu bogatu vlaknima, voće, povrće i cjelovite žitarice. Pijte puno vode. Izbjegavajte naprezanje dok imate stolicu. Održavajte redovitu higijenu. Preporuča se stavljanje hladnih obloga na otečena mjesta i kupki.`,
    column: 2,
  },
  {
    title: 'Kada i kako se izdojiti? ',
    body: `Izdajanje mlijeka iz dojki moguće je ručno ili upotrebom izdajalice. Smjestite se na neko mirno i ugodno mjesto, opustite i krenite s prikupljanjem mlijeka. Provjerite kako ručno izdojiti mlijeko. Ukoliko ste mlijeko odlučili izdojiti upotrebom izdajalice tada navlažite dojku kako bi silikonski dio što bolje prijanjao koži te krenite lagano pumpati. Ono na što je važno obratiti pažnju je da svi dijelovi koji dolaze u dodir s mlijekom i dojkom budu vrlo čisti i uredni.`,
    column: 1,
  },
  {
    title: 'Često mi se dogodi da mi pobjegne mokraća, kako to spriječiti? ',
    body: `Inkontinencija je češća tijekom i nakon trudnoće, a najprisutnija je prilikom kašljanja, smijanja i napinjanja. Trudnoća, a zatim i porod, rastežu dno zdjelice i mogu uzrokovati oštećenje živaca i mišića zdjelice. Ovaj problem najčešće se rješava sam u mjesecima nakon poroda, a u međuvremenu ublažite stanje nošenjem uložaka i učestalim izvođenjem Kegelovih vježbi. Još jedan od problema koji se javljaju nakon porođaja je zatvor. Nekoliko je mogućih uzroka, poput rastegnutih i opuštenih trbušnih mišića, koji mogu usporiti prolazak sadržaja kroz crijeva. Uzrok može biti i strah da se dodatno ne ozlijedite i ne pojačate bol u predjelu međice i anusa. Dodatni problem može biti i inkontinencija stolice, koja može biti uzrokovana rastezanjem i slabljenjem mišića dna zdjelice, pucanjem međice ili oštećenjem živaca. Najčešće se događa kod porođaja prirodnim putem koji su dugo trajali. `,
    column: 2,
  },
  {
    title: 'Kako skladištiti izdojeno mlijeko?',
    body: `Mlijeko možete spremiti u čiste plastične bočice ili vrećice za čuvanje mlijeka. Na svaku napišite datum da znate do kada ga možete iskoristiti. Boja mlijeka neka Vas ne brine - iako je vodenasto i prozirno, ono je bogato hranjivim sastojcima u mjeri potrebnoj za razvoj Vašeg djeteta. Mlijeko možete pohraniti na sobnoj temperaturi do 10 sati, u hladnjaku na ne dulje od osam dana, a u zamrzivaču hladnjaka ne dulje od dva tjedna. Ukoliko imate poseban dubinski zamrzivač mlijeko možete skladištiti i na dulje vrijeme. Kada ga zatrebate, mlijeko ostavite ga na sobnoj temperaturi ili u posudi s toplom vodom, nikako ga nemojte zagrijavati u mikrovalnoj pećnici.`,
    column: 1,
  },
  {
    title: 'Kada će se smanjiti postporođajni  bolovi? ',
    body: `Kroz period od šest tjedana Vaša bi se maternica trebala smanjiti do svoje normalne veličine. Kako se maternica skuplja, Vi bi mogli osjećati bolove. Te kontrakcije su obično blage, pogotovo nakon prvog porođaja. Kako bebino sisanje potiče oslobađanje oksitocina, pojačano stezanje mogli bi posebno osjećati tijekom dojenja. Prodišite svaku kontrakciju i pokušajte se opustiti. Ukoliko osjetite da dobivate temperaturu ili imate bolove dulje od tjedan dana, javite se liječniku.`,
    column: 2,
  },
  {
    title: 'Kada uvesti bočicu?',
    body: `U početku je važno dojiti svoju bebu. Za Vas kako bi se osjećali dobro i lijepo i kako bi se uspostavila proizvodnja mlijeka, a za Vašu bebu kako bi osjećala Vašu toplinu, miris i Vas. Kada se dojenje ustali možete bebi početi uvoditi i bočicu. Ona će Vam pomoći u slučajevima kada trebate obaviti stvari izvan kuće, a ujedno će dati priliku i tati da koji puta nahrani bebu. Neka djeca prihvaćaju, a neka ne prihvaćaju bočicu, jednako kao i dojku, te to ovisi od djeteta do djeteta. Osjećaj dude i osjećaj dojke u ustima djeteta je različit. Ukoliko u početku ne ide, možda će ići nakon nekog vremena, stoga pokušajte ponovno. Količinu mlijeka odredite prema želji djeteta.`,
    column: 1,
  },
  {
    title: 'Imam lohije (iscjedak iz rodnice), kako si olakšati? ',
    body: `Slojevi maternice koji se nakon poroda čiste izazivat će iscjedak. On u početku može biti jarko crven, obilan i prošaran ugrušcima. On varira u količini, izgledu i trajanju, mijenja boju u ružičastu, pa smećkastu, nakon desetak dana žutu i na kraju bijelu. Može trajati dva do osam tjedana. Kako bi si olakšali koristite uloške. Javite se svom liječniku ako imate vrtoglavicu, velike količine iscjetka, ukoliko osjećate neugodan miris, imate temperaturu, ugrušci su veličine dječje šake ili veći te ukoliko nakon prestanka iscjedak ponovno postane crven.`,
    column: 2,
  },
  {
    title: 'Kako hraniti dijete bočicom? ',
    body: `Udobno se smjestite - najbolje će Vam poslužiti stolica s naslonima za ruke ili fotelja. Dijete treba biti u polusjedećem položaju kako bi lakše gutalo mlijeko iz bočice. Kada ste spremni, dudom dodirnite kut djetetovih usana ili same usne i dijete će uhvatiti dudu i početi sisati. Bočicu držite u položaju u kojem je duda uvijek bude puna mlijeka kako se dijete ne bi nagutalo zraka. Ukoliko dijete zaspi za vrijeme hranjenja, nježno ga probudite i nastavite s hranjenjem. Nakon hranjenja stavite dijete u položaj za izbacivanje suvišnog zraka.`,
    column: 1,
  },
  {
    title: 'Primijetila sam da mi kosa pretjerano opada, je li to normalno? ',
    body: `Nakon rođenja Vaše bebe mogli bi primijetiti učestali gubitak kose. To se događa pod utjecajem hormona koji sprečavaju uobičajeni gubitak kose kroz trudnoću. Nakon porođaja Vaše će tijelo odbaciti sav višak nakupljenih dlačica, no to je samo privremeno dok se Vaša kosa ponovno ne počne vraćati u normalu. Ne pretjerujte s korištenjem šampona, koristite regeneratore i, najvažnije, hranite se zdravo. Zasigurno ste primijetili da Vam je koža dobila tamnije fleke nepravilnih oblika kroz trudnoću, posebno koža lica. Primijetili ste i tamnu liniju koja se proteže niz trbuh, što je isto tako normalna pojava i obično nestaje u mjesecima nakon poroda. Mogle bi Vam popucati i krvne žilice zbog napora i tiskanja tijekom poroda te ostaviti sitne mrljice na licu koje će nestati u nekoliko dana nakon poroda. Ono što možete dobiti, a neće nestati nakon poroda, su strije. Strije mogu posvijetliti, ali ne i nestati.`,
    column: 2,
  },
  {
    title: 'Kada je vrijeme za prvi pregled nakon porođaja? ',
    body: `Idealno vrijeme da dobijete odgovor na sve što Vas muči je četiri do šest tjedana nakon poroda. Liječnik će pregledati rodnicu, vrat maternice, rez epiziotomije ili carskog reza, te vidjeti veličinu i oblik maternice. Ovo je također vrijeme da se savjetujete oko kontracepcije koju namjeravate koristiti.`,
    column: 1,
  },
  {
    title: 'Kako će izgledati moj život i osjećaji nakon poroda?',
    body: `Nakon poroda u Vašem životu i Vašoj obitelji puno toga se mijenja. U početku se trebate upoznati s bebom, povezati i postati obitelj. Vaši osjećaji bit će intenzivni, promjenljivi, često ćete mijenjati raspoloženje i mogle bi Vas mučiti potištenost i depresija. Briga za bebu vrlo je zahtjevan posao koji bi u početku Vas i Vašeg partnera mogao iscrpiti i preokrenuti Vam život. Bit će teško nositi se s novim odgovornostima ali vidjet ćete da Vi možete sve, samo korak po korak. Nakon nekog vremena, kada se uhodate, sve će biti puno lakše i jednostavnije.`,
    column: 1,
  },
  {
    title: 'Kako smanjiti tjelesnu masu nakon poroda?',
    body: `Zdravom prehranom i redovitim vježbanjem. Nakon poroda mogli bi se osjećati tromo, bespomoćno, loše i izvan forme. Sami ćete sebi izgledati kao da ste još uvijek trudni. To je potpuno normalno, pogotovo za žene koje su više puta rodile. Najvjerojatnije će Vam trebati tri do šest mjeseci da se riješite kilograma koje ste dobili u trudnoći. Na samom porodu izgubit ćete između tri i pet kilograma, uključujući težinu vaše bebe, posteljice i plodove vode. Koliko ćete kilograma izgubiti ovisi o Vašoj prehrani, kretanju i vježbi. Sretno!`,
    column: 2,
  },
  {
    title: 'Smjernice za postporođajno razdoblje',
    body: `Prihvatite svu pomoć koja Vam se nudi kako bi taj prvi period prošao uz što manje stresa i nervoze. Kada god Vam se pruži prilika izađite iz kuće i popijte kavu s prijateljicama, prošećite s partnerom ili uzmite bebu i izađite svi zajedno van u šetnju. Pokušajte eliminirati krivnju, svi mi nešto smo radili bolje, nešto lošije, nešto nam je išlo od ruke, nešto ne. Pojednostavite si život, nemojte previše komplicirati već se savjetujte s majkama oko Vas koje su sve to prošle i sigurno znaju cijeli niz prečica do cilja. Nađite malo vremena za sebe, prošećite, odmorite se u toploj kupki ili razgovoru sa sebi dragim ljudima. Njegujte vezu sa svojim partnerom jer i njemu treba podrška.`,
    column: 1,
  },
  {
    title: 'Kako i koliko jesti u danima nakon poroda?',
    body: `Hranite se zdravo! Posebno ako dojite, prehrana u danima majčinstva vrlo je važna za Vaše zdravlje i zdravlje Vaše bebe. Jedite hranu koja uključuje puno voća, povrća, vlakana, žitarica i nemasnih izvora bjelančevina. Nemojte se izgladnjivati ili preskakati obroke, jedite malo po malo i jedite zdravo.`,
    column: 2,
  },
  {
    title: 'Vježbanje u danima majčinstva, da ili ne? ',
    body: `Redovito vježbanje dat će Vam dodatnu snagu, pomoći u borbi protiv umora, poboljšat će cirkulaciju, olakšati bolove u leđima i pomoći Vam u oporavku od poroda. Na psihološkoj razini povećat će Vašu sposobnost da se nosite sa stresom i tegobama roditeljstva. Također, lakše ćete se vratiti u formu od prije poroda, pogotovo ako ste vježbali prije i za vrijeme trudnoće i imali lagan i prirodan porod. Za početak možete krenuti s Kegelovim vježbama, ako se osjećate dobro već i nekoliko dana nakon poroda. Ako ste imali težak porod ili ste rodili carskim rezom savjetujte se s liječnikom o tome kada je pravo vrijeme. Počnite polako i nemojte očekivati da će se sve vratiti na svoje mjesto u kratkom roku. Hodanje i plivanje su aktivnosti koje se preporučaju za sami početak. Odaberite sami tempo koji Vam odgovara.`,
    column: 2,
  },
  {
    title: 'Susretne li se svatko s postporođajnom depresijom?',
    body: `Nakon porođaja mnoge se žene osjećaju tužno i depresivno. Do toga dolazi uslijed smanjene razine hormona, preopterećenosti, umora, velikih promjena koje se događaju unutar nas samih, unutar obitelji, očekivanja, potpore partnera. Neki muškarci se također teško nose s novonastalom situacijom. Postporođajna depresija najčešće se pojavljuje tri do pet dana nakon porođaja i varira u svojoj duljini. Pokušajte si pomoći tako da se više odmarate, gibate i zdravo se hranite. Pokušajte razgovarati s partnerom i sebi bliskom osobom koja će Vas saslušati i razumjeti. Ukoliko se osjećate zaista loše kroz dulje vrijeme, svakako potražite savjet liječnika.`,
    column: 1,
  },
  {
    title: 'Kako se što lakše povezati sa svojom bebom? ',
    body: `Osluškujte svoje dijete, ono će Vam sve reći i pokazati. Nosite ga, milujte, pazite, mazite, pričajte mu, čitajte, pustite ga da Vas gleda, sluša, razgovarajte s njim. Jednako kao što Vaše dijete treba hranu, tako treba i kontakt. Tijekom vremena Vaši će osjećaji prema djetetu postajati sve jači i jači, bolje ćete razumjeti djetetove potrebe i znakove koje Vam daje. Svi ukućani malo po malo priviknut će se i naučiti ponašati u djetetovom društvu. Nemojte se bojati da ćete razmaziti svoje dijete, ono je malo i sad treba samo Vašu toplinu i ljubav.`,
    column: 1,
  },
  {
    title: 'Kako njegovati svoju vezu? ',
    body: `Postporođajno razdoblje uključuje niz velikih promjena u Vašem životu. Kako bi zaživjeli kao roditelji i opstali kao par, trebate njegovati svoj odnos. Podijelite poslove u kući i oko bebe, razgovarajte međusobno o svemu, donosite odluke zajedno, odmarajte se naizmjence. Provedite neko vrijeme sami jedno s drugim, odvojeno od bebe. Polako se vratite seksualnim odnosima kad osjetite da je vrijeme. Budite strpljivi i budite si podrška i potpora u svakom obliku.`,
    column: 2,
  },
]
