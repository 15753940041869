import React from "react"

const SvgSearch = props => (
  <svg width={17} height={17} fill="none" {...props}>
    <path
      d="M.336 15.478l5.154-5.155a6.157 6.157 0 01-1.188-2.715 6.324 6.324 0 01.191-2.949 6.09 6.09 0 011.549-2.567C6.834 1.3 7.753.777 8.8.522a6.633 6.633 0 013.203.022 6.085 6.085 0 012.8 1.612c.806.806 1.343 1.74 1.612 2.8a6.306 6.306 0 01.021 3.203c-.254 1.047-.777 1.966-1.57 2.758a6.03 6.03 0 01-2.545 1.527 6.603 6.603 0 01-2.97.212c-.976-.155-1.88-.551-2.715-1.188L1.48 16.623.336 15.478zm6.767-5.622a4.62 4.62 0 002.12 1.23c.821.199 1.634.192 2.44-.02a4.46 4.46 0 002.143-1.21 4.256 4.256 0 001.167-2.1 4.75 4.75 0 00-.022-2.397 4.459 4.459 0 00-1.209-2.142 4.459 4.459 0 00-2.142-1.21 5 5 0 00-2.419-.042 4.406 4.406 0 00-2.078 1.188 4.62 4.62 0 00-1.23 2.121 5.171 5.171 0 000 2.461 4.62 4.62 0 001.23 2.121z"
      fill="#26252B"
      fillOpacity={0.75}
    />
  </svg>
)

export default SvgSearch
